import React from 'react';

import styles from '../../../../EditConfigurationsForm.module.scss';

import { DaysBeforeLateFeeAppliedWeekly } from './DaysBeforeLateFeeAppliedWeekly';
import { DaysBeforeLateFeeAppliedBiWeekly } from './DaysBeforeLateFeeAppliedBiWeekly';
import { DaysBeforeLateFeeAppliedSemiMonthly } from './DaysBeforeLateFeeAppliedSemiMonthly';
import { DaysBeforeLateFeeAppliedMonthly } from './DaysBeforeLateFeeAppliedMonthly';
import { StateFeesProps } from '../StateFees';

export const DaysBeforeLateFeeApplied = (props: StateFeesProps) => {
  return (
    <div className={styles.tableResponsive}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th scope="col">
              <span className={styles.label}>Weekly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Bi-weekly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Semi-monthly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Monthly</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <DaysBeforeLateFeeAppliedWeekly {...props} />
            </td>
            <td>
              <DaysBeforeLateFeeAppliedBiWeekly {...props} />
            </td>
            <td>
              <DaysBeforeLateFeeAppliedSemiMonthly {...props} />
            </td>
            <td>
              <DaysBeforeLateFeeAppliedMonthly {...props} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
