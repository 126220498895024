import React, { useState } from 'react';

import styles from './LDW.module.scss';

import { LDWTabPanel } from './LDWTabPanel';
import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { Configurations } from '../../../../../../domain/Configuration/Configurations';
import { GeneralKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { Modal, ModalContent } from '../../../../../common/modal/Modal';
import { Loading } from '../../../../../common/loader/Loading';
import { StatesTabs } from '../../../StatesTabs/StatesTabs';

export const LDWTestId = 'LDWTestId';

interface Props {
  readonly configurations: Configurations;
  readonly disabled?: boolean;
}

export const LDW = (props: Props) => {
  const { configurations, disabled } = props;

  const [modalCount, setModalCount] = useState(0);

  const setModalOpen = (state: boolean) => {
    if (state) {
      setModalCount(modalCount + 1);
    } else {
      setModalCount(modalCount - 1);
    }
  };

  return (
    <>
      <StatesTabs
        data-testid={LDWTestId}
        rulesKeys={Object.values(GeneralKeys)}
        scrollOnErrors={!disabled}
        renderState={(stateAbbriviation: StateAbbreviation) => (
          <LDWTabPanel
            key={stateAbbriviation}
            setModalOpen={(value: boolean) => setModalOpen(value)}
            configurations={configurations}
            currentStateAbbr={stateAbbriviation}
            disabled={disabled}
          />
        )}
      />

      <Modal isOpen={modalCount > 0} closable={false}>
        <ModalContent>
          <Loading
            className={styles.loadingContainer}
            text="Fetching club type, please wait"
          />
        </ModalContent>
      </Modal>
    </>
  );
};
