import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { Switch as RacSwitch, SwitchProps } from '@rentacenter/racstrap';

import styles from './Switch.module.scss';

interface Props extends SwitchProps {
  changed?: boolean;
}

export const Switch = ({ name, changed, classes, ...rest }: Props) => {
  const { register, setValue } = useFormContext();

  const onChange = (checked: boolean) => {
    setValue(name, checked, { shouldDirty: true, shouldValidate: true });
  };

  // Because mui doesn't support uncontrolled Switch-es
  // https://github.com/react-hook-form/react-hook-form/issues/459#issuecomment-549578316
  useEffect(() => {
    register({ name });
  }, [name, register]);

  return (
    <RacSwitch
      name={name}
      onChange={(_event, checked) => {
        onChange(checked);
      }}
      classes={{
        switch: clsx(changed && styles.changedSwitch),
        track: styles.track,
        thumb: styles.thumb,
        checked: styles.checked,
        disabled: styles.disabled,
        ...classes
      }}
      {...rest}
    />
  );
};
