import React, { useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Label } from '@rentacenter/racstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import get from 'lodash/get';
import clsx from 'clsx';

import styles from '../Payment.module.scss';

import { InputList } from '../../../../../common/form/input-list/InputList';
import { PaymentKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import {
  Modal,
  ModalButtons,
  ModalContent
} from '../../../../../common/modal/Modal';
import { SACInput } from './SACInput';
import { Configurations } from '../../../../../../domain/Configuration/Configurations';

interface Props {
  readonly disabled?: boolean;
  readonly sacKey:
    | PaymentKeys.SameAsCashDaysInputs
    | PaymentKeys.SameAsCashDaysPrintedInputs;
  readonly configurations: Configurations;
}

const titles = {
  [PaymentKeys.SameAsCashDaysInputs]: 'Same as Cash Days',
  [PaymentKeys.SameAsCashDaysPrintedInputs]: 'Same as Cash Days Printed'
};

export const addSacTestId = 'addSacTestId';
export const removeSacTestId = 'removeSacTestId';
export const modalConfirmBtn = 'modalConfirmBtn';

export const SameAsCashDaysInputs = ({
  disabled,
  sacKey,
  configurations
}: Props) => {
  const { getValues } = useFormContext();
  const sacFieldName = `COUNTRY.${sacKey}.value.sameascash`;
  const { fields, append, remove } = useFieldArray({ name: sacFieldName });

  const [confirmOpened, setConfirmOpened] = useState(false);
  const indexToRemove = useRef(-1);

  const addSac = () => {
    append({ term: '', sac: '' });
  };

  const removeSac = () => {
    remove(indexToRemove.current);
    const configs = get(configurations, sacFieldName);
    configs.splice(indexToRemove.current, 1);
    setConfirmOpened(false);
  };

  const openConfirm = (index: number) => {
    indexToRemove.current = index;
    const term = get(getValues(), `${sacFieldName}[${index}].term`);
    const sac = get(getValues(), `${sacFieldName}[${index}].sac`);

    if (term || sac) {
      setConfirmOpened(true);
    } else {
      removeSac();
    }
  };

  return (
    <>
      <Modal
        isOpen={confirmOpened}
        onClose={() => setConfirmOpened(false)}
        title="Please Confirm"
      >
        <ModalContent>Are you sure you want to remove this row ?</ModalContent>
        <ModalButtons>
          <Button outlined onClick={() => setConfirmOpened(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={removeSac}
            data-testid={modalConfirmBtn}
          >
            Yes, Remove
          </Button>
        </ModalButtons>
      </Modal>
      <InputList
        title={titles[sacKey]}
        noDividerLine={sacKey === PaymentKeys.SameAsCashDaysInputs}
      >
        <table className={styles.sacTable}>
          <thead>
            <tr>
              <td>
                <Label>Term of Agreement</Label>
              </td>
              <td>
                <Label>Days</Label>
              </td>
              <td className={styles.deleteBtnCell}></td>
            </tr>
          </thead>

          <tbody>
            {fields.map((field, index) => (
              <tr key={field.id} role="row">
                <td>
                  <SACInput
                    fieldname={`${sacFieldName}[${index}].term`}
                    disabled={disabled}
                    sacKey={sacKey}
                    {...(index > 0 && {
                      prevFieldName: `${sacFieldName}[${index - 1}].term`
                    })}
                  />
                </td>
                <td>
                  <SACInput
                    fieldname={`${sacFieldName}[${index}].sac`}
                    disabled={disabled}
                    sacKey={sacKey}
                  />
                </td>
                <td className={clsx([styles.buttonCell, styles.deleteBtnCell])}>
                  {fields?.length > 1 && !disabled && (
                    <span
                      onClick={() => openConfirm(index)}
                      data-testid={removeSacTestId}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  )}
                </td>
              </tr>
            ))}
            {fields?.length < 10 && !disabled && (
              <tr>
                <td colSpan={3} className={styles.buttonCell}>
                  <span onClick={addSac} data-testid={addSacTestId}>
                    <FontAwesomeIcon icon={faPlus} /> Add Another
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </InputList>
    </>
  );
};
