import React, { useContext } from 'react';
import { get } from 'lodash';

import { Switch } from '../../../../../common/form/switch/Switch';
import { FixedAmountLateFeeKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { Configurations } from '../../../../../../domain/Configuration/Configurations';
import { useConfigurationsStateContext } from '../../../../../../context/configurations/configurations-contexts';
import { FieldChangedContext } from '../../../FieldChangedContext';
import { LateFeeCommonProps } from '../StateFees/LateFee/LateFeeAmount';

export interface Props extends LateFeeCommonProps {
  readonly configurations: Configurations;
}

export const MultipleLateFeesAllowed = ({
  disabled,
  stateAbbr,
  configurations,
  freshStrategy
}: Props) => {
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { fieldChanged } = useContext(FieldChangedContext);

  const ruleName = `${stateAbbr}.${FixedAmountLateFeeKeys.MultipleAllowed}`;
  const fieldName = `${ruleName}.value`;

  const presentInApi = !!get(initialConfigurations, ruleName);

  return (
    <Switch
      name={fieldName}
      disabled={disabled || (!presentInApi && !freshStrategy)}
      defaultChecked={
        presentInApi || freshStrategy ? get(configurations, fieldName) : false
      }
      changed={fieldChanged(fieldName)}
      isSeparatorVisible={false}
      isValueVisible
    />
  );
};
