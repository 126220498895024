import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './TabPanel.module.scss';

export interface TabPanelProps {
  readonly children?: ReactNode;
  readonly index: number;
  readonly value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`edit-tabpanel-${index}`}
      aria-labelledby={`edit-tab-${index}`}
      className={clsx(styles.root, value === index && styles.active)}
      {...other}
    >
      {children}
    </div>
  );
};
