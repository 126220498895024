import { Store } from '../../domain/Store/Store';
import { StoresByCriteria } from '../configurations/selected-stores/selected-stores-reducer';

export interface StoresSelectors {
  getStoresByEntity(): StoresByCriteria;

  getStoresByState(): StoresByCriteria;
}

export const getStoresByEntity = (stores?: Store[]) => (): StoresByCriteria => {
  if (!stores) {
    return {};
  }

  return stores.reduce(
    (acc: StoresByCriteria, store: Store): StoresByCriteria => {
      if (acc[store.franchiseeName]) {
        acc[store.franchiseeName].push(store);
      } else {
        acc[store.franchiseeName] = [store];
      }

      return acc;
    },
    {}
  );
};

export const getStoresByState = (stores?: Store[]) => (): StoresByCriteria => {
  if (!stores) {
    return {};
  }

  return stores
    .sort((storeA, storeB) =>
      storeA?.state?.abbreviation?.localeCompare(storeB.state.abbreviation)
    )
    .reduce((acc: StoresByCriteria, store: Store): StoresByCriteria => {
      if (acc[store.state.name]) {
        acc[store.state.name].push(store);
      } else {
        acc[store.state.name] = [store];
      }

      return acc;
    }, {});
};
