import { Configurations } from '../../../domain/Configuration/Configurations';
import { InitialConfigurationsActionType } from './initial-configurations-actions';
import { ConfigurationsAction } from '../configurations-reducer';
import { SelectedStoresActionType } from '../selected-stores/selected-stores-actions';

export interface InitialConfigurationsState {
  readonly isPending: boolean;
  readonly hasError: boolean;
  readonly configurations?: Configurations;
}

export const initialConfigurationsDefaultState: InitialConfigurationsState = {
  isPending: false,
  hasError: false
};

export const initialConfigurationsReducer = (
  state: InitialConfigurationsState,
  action: ConfigurationsAction
): InitialConfigurationsState => {
  switch (action.type) {
    case SelectedStoresActionType.SELECT_STORES:
    case SelectedStoresActionType.DESELECT_STORES:
    case SelectedStoresActionType.SET_INITIAL_STATE:
      return initialConfigurationsDefaultState;

    case InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS:
      return {
        isPending: true,
        hasError: false
      };

    case InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS_SUCCESS:
      return {
        isPending: false,
        hasError: false,
        configurations: action.payload.configurations
      };

    case InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS_ERROR:
      return {
        isPending: false,
        hasError: true
      };

    default:
      return state;
  }
};
