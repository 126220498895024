import React from 'react';
import clsx from 'clsx';
import { Input, InputProps, Label } from '@rentacenter/racstrap';

import styles from './InputField.module.scss';
import { InputHelp } from '../input-help/InputHelp';

interface InputFieldProps {
  changed?: boolean;
  tooltip?: React.ReactNode;
}

type Props = InputFieldProps & InputProps;

export const InputField = ({
  changed,
  invalid,
  errorMessage,
  classes,
  label,
  tooltip,
  ...rest
}: Props) => {
  return (
    <>
      {label && (
        <Label>
          {label} {tooltip && <InputHelp text={tooltip} />}
        </Label>
      )}

      <Input
        invalid={invalid}
        errorMessage={errorMessage}
        classes={{
          ...classes,
          input: clsx([
            !invalid && !errorMessage && changed && styles.changed,
            classes?.input
          ]),
          disabled: clsx(styles.disabled, classes?.disabled)
        }}
        {...rest}
      />
    </>
  );
};
