import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AutoNumeric } from '../../../../../common/form/auto-numeric/AutoNumeric';
import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { PaymentKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { useRuleField } from '../../../useRuleField';

interface Props {
  readonly currentStateAbbr: StateAbbreviation;
  readonly disabled?: boolean;
}

export const EPOPercentOfRemainingRent = (props: Props) => {
  const { currentStateAbbr, disabled } = props;
  const { control } = useFormContext();

  const { fieldName, options, rules, fieldError, changed } = useRuleField(
    `${currentStateAbbr}.${PaymentKeys.EPOPercentRemainingRent}`
  );

  return (
    <Controller
      as={AutoNumeric}
      control={control}
      name={fieldName}
      label="EPO Percent"
      options={options}
      rules={rules}
      disabled={disabled}
      errorMessage={fieldError?.message}
      changed={changed}
    />
  );
};
