import React from 'react';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';

import styles from './Payment.module.scss';

import { PaymentKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { StateAbbreviation } from '../../../../../domain/Store/State';
import { useConfigurationsValidationContext } from '../../../../../context/configurations/configurations-contexts';
import { AutoNumeric } from '../../../../common/form/auto-numeric/AutoNumeric';

interface Props {
  readonly currentStateAbbr: StateAbbreviation;
}

export const TotalCostLimit = ({ currentStateAbbr }: Props) => {
  const validation = useConfigurationsValidationContext();

  const fieldName = `${currentStateAbbr}.${PaymentKeys.TotalCostLimit}.value`;
  const { options } = get(validation, fieldName);
  return (
    <Controller
      as={AutoNumeric}
      className={styles.totalCostLimit}
      label="Total Cost Limit"
      tooltip={
        <>
          Applies to <br />{' '}
          (MultipleOfCostStrategy/PercentageOfRentalIncomeStrategy)
        </>
      }
      options={options}
      name={fieldName}
      disabled
    />
  );
};
