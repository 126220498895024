import React from 'react';

import {
  StoreListItem,
  StoreListItemSelectEvent
} from './store-list-item/StoreListItem';
import { StoresByCriteria } from '../../../context/configurations/selected-stores/selected-stores-reducer';

interface Props {
  stores: StoresByCriteria;
  selectedStores: StoresByCriteria;
  onItemCheck?: (e: StoreListItemSelectEvent) => void;
}

export const storeListTestId = 'storeListTestId';

export const StoreList = (props: Props) => {
  const { stores, selectedStores, onItemCheck } = props;

  const storeKeys = Object.keys(stores);

  return (
    <div data-testid={storeListTestId}>
      {storeKeys.sort().map((criteriaName: string) => (
        <StoreListItem
          key={criteriaName}
          checked={!!selectedStores[criteriaName]}
          criteriaName={criteriaName}
          numberOfStores={
            stores[criteriaName] ? stores[criteriaName].length : 0
          }
          onCheck={onItemCheck}
        />
      ))}
    </div>
  );
};
