import React from 'react';

import styles from '../../../EditConfigurationsForm.module.scss';

import { BoundedAmountsLateFeeKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsStateContext } from '../../../../../../context/configurations/configurations-contexts';
import { countUniqRules } from '../../../../../../context/configurations/configurations-selectors';
import { LateFeeAmountWeekly1 } from './LateFeeAmountWeekly1';
import { LateFeeAmountWeekly2 } from './LateFeeAmountWeekly2';
import { LateFeeAmountWeeklyBoundary } from './LateFeeAmountWeeklyBoundary';
import { LateFeeAmountMonthly1 } from './LateFeeAmountMonthly1';
import { StateFeesProps } from '../StateFees/StateFees';

export const BoundedAmountsLateFee = (props: StateFeesProps) => {
  const { currentStateAbbr, disabled } = props;

  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();

  const initialConfig = {
    [currentStateAbbr]: (initialConfigurations || {})[currentStateAbbr],
    COUNTRY: {}
  };

  const freshStrategy =
    countUniqRules(initialConfig)(Object.values(BoundedAmountsLateFeeKeys)) ===
    0;

  return (
    <div className={styles.tableResponsive}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th scope="col">
              <span className={styles.label}>Late Fee Amount Weekly 1</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Late Fee Amount Weekly 2</span>
            </th>
            <th scope="col">
              <span className={styles.label}>
                Late Fee Amount Weekly Boundary
              </span>
            </th>
            <th scope="col">
              <span className={styles.label}>Late Fee Amount Monthly 1</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <LateFeeAmountWeekly1
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
            <td>
              <LateFeeAmountWeekly2
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
            <td>
              <LateFeeAmountWeeklyBoundary
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
            <td>
              <LateFeeAmountMonthly1
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
