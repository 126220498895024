import { createContext, useContext } from 'react';

import { StoresThunks } from './stores-thunks';
import { StoresState, initialState } from './stores-reducer';
import { StoresSelectors } from './stores-selectors';

export const StoresStateContext = createContext<StoresState>(initialState);

export const StoresSelectorsContext = createContext<StoresSelectors>(
  {} as StoresSelectors
);

export const StoresThunksContext = createContext<StoresThunks>(
  {} as StoresThunks
);

export const useStoresStateContext = (): StoresState =>
  useContext(StoresStateContext);

export const useStoresSelectorsContext = (): StoresSelectors =>
  useContext(StoresSelectorsContext);

export const useStoresThunksContext = (): StoresThunks =>
  useContext(StoresThunksContext);
