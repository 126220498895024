import React, { useContext } from 'react';
import { get } from 'lodash';

import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { Configurations } from '../../../../../../domain/Configuration/Configurations';
import { GeneralKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { FieldChangedContext } from '../../../FieldChangedContext';
import { Switch } from '../../../../../common/form/switch/Switch';

export interface Props {
  readonly disabled?: boolean;
  readonly configurations: Configurations;
  readonly currentStateAbbr: StateAbbreviation;
}

export const LDWAllowedOnFinalPayment = (props: Props) => {
  const { configurations, currentStateAbbr } = props;
  const { fieldChanged } = useContext(FieldChangedContext);

  const fieldName = `${currentStateAbbr}.${GeneralKeys.LDWAllowedOnFinalPayment}`;
  const fieldValue = `${fieldName}.value`;

  const changed = fieldChanged(fieldValue);

  return (
    <Switch
      label="LDW allowed on final payment"
      name={fieldValue}
      disabled
      defaultChecked={get(configurations, fieldValue)}
      changed={changed}
      isSeparatorVisible={false}
      isValueVisible
    />
  );
};
