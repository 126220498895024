import { omit } from 'lodash';
import { CancelToken } from 'axios';

import { client } from './client';
import { Store } from '../domain/Store/Store';
import { State, StateAbbreviation } from '../domain/Store/State';
import { Configurations } from '../domain/Configuration/Configurations';
import { getNameForAbbreviation } from '../config/usStates';
import {
  coerceValues,
  fillRulesWithExtraData,
  flattenRules,
  extractChanges,
  formatValues,
  isValidStateKey,
  unflattenRules
} from './apiConfigsUtils';
import { StoresByState } from '../context/configurations/selected-stores/selected-stores-selectors';

const addStateToStore = (store: any): Store => {
  const state: State = {
    abbreviation: store.storeState,
    name: getNameForAbbreviation(store.storeState) || ''
  };

  return omit(
    {
      ...store,
      state
    },
    ['storeState']
  ) as Store;
};

export const getStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_OWNER_CONFIG',
    { method: 'GET' },
    true
  ).then((stores: Store[]): Store[] => stores.map(addStateToStore));

export const getConfigurations = (
  storeNums: string[],
  cancelToken: CancelToken
) =>
  client(
    'configs',
    {
      method: 'GET',
      params: { storeNumbers: storeNums.join(',') },
      cancelToken
    },
    false
  )
    .then(flattenRules)
    .then(coerceValues);

export const getClubId = (stateAbbr: StateAbbreviation) =>
  client(
    `configs/clubtype/${stateAbbr}`,
    {
      method: 'GET'
    },
    false
  );

export const submitConfigurations = (
  configurations: Configurations,
  selectedStores: StoresByState,
  initialConfigurations?: Configurations
) => {
  const changes = extractChanges(configurations, initialConfigurations);

  let hasChanges = false;

  Object.keys(changes).forEach(changedKey => {
    const excludedKeys = ['epoStrategy', 'monthlyStrategy'];
    const keys = Object.keys(changes[changedKey]).filter(
      key => !excludedKeys.includes(key)
    );

    if (isValidStateKey(changedKey) && keys.length > 0) {
      hasChanges = true;
    }
  });

  if (!hasChanges) {
    return Promise.reject('no change');
  }

  configurations = fillRulesWithExtraData(
    configurations,
    changes,
    initialConfigurations
  );

  Object.keys(configurations).forEach(k => {
    const key = k as StateAbbreviation | 'COUNTRY';
    const configuration = configurations[key];

    if (!isValidStateKey(key)) {
      delete configurations[key];
    } else if (configuration) {
      configuration['selectedStores'] = selectedStores[key];
      delete configuration['monthlyStrategy'];
      delete configuration['epoStrategy'];
    }
  });

  const apiConfigs = unflattenRules(formatValues(configurations));

  return client('configs', { method: 'PUT', body: apiConfigs }, false);
};
