import { Action } from '../../Action';
import { Configurations } from '../../../domain/Configuration/Configurations';

export enum ChangeConfigurationsActionType {
  CHANGE_CONFIGURATIONS = 'CHANGE_CONFIGURATIONS'
}

export interface ChangeConfigurationsAction extends Action {
  readonly type: ChangeConfigurationsActionType.CHANGE_CONFIGURATIONS;
  readonly payload: {
    configurations?: Configurations;
    isTouched?: boolean;
  };
}

export const changeConfigurationsAction = (
  configurations: Configurations,
  isTouched: boolean
): ChangeConfigurationsAction => ({
  type: ChangeConfigurationsActionType.CHANGE_CONFIGURATIONS,
  payload: { configurations, isTouched }
});
