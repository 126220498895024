import { CurrencyPlacement, NumericInputOptions } from '@rentacenter/racstrap';
import { SelectFieldOption } from '../components/common/form/select-field/SelectField';

export const configurationsFormId = 'configurationsForm';

export enum ConfigurationGroupTitle {
  general = 'General configurations',
  inventoryDepreciation = 'Inventory depreciation',
  fees = 'Fees',
  payment = 'Payment'
}

export enum InputListTitle {
  applyProcessingFee = 'Apply processing fee',
  cellphone911FeeAmount = 'Cellphone 911 fee',
  chargeBackFee = 'Chargeback fee',
  NSFFee = 'NSFFee',
  payByPhoneFee = 'Pay by phone',
  daysBeforeLateFeeApplied = 'Days Before Late Fee Is Applied',
  installmentDaysBeforeLateFeeApplied = 'Installment Note Days Before Late Fee is Applied',
  lateFeeAmount = 'Late fee amount',
  LDWRate = 'LDW Rate',
  LDWRateMinAmnt = 'Min. amnt.',
  LDWRateMaxAmnt = 'Max. amnt.'
}

const requiredMessage = 'Value is required';

interface Rules {
  required?: {
    message: string;
  };
  min?: {
    message: string;
  };
  max?: {
    message: string;
  };
  percentage?: {
    max?: {
      message: string;
    };
  };
  currency?: {
    max?: {
      message: string;
    };
  };
  days?: {
    max?: {
      message: string;
    };
  };
}

interface Setting {
  readonly label: string;
  readonly name: string;
  readonly placeholder?: string;
  readonly rules?: Rules;
  readonly autonumericOptions?: NumericInputOptions;
  readonly selectOptions?: SelectFieldOption[];
}

export interface EditConfigsFormSettings {
  readonly general: {
    readonly LDWAllowedOnFinalPayment: Setting;
    readonly LDWMinAmountWeekly: Setting;
    readonly LDWMinAmountBiWeekly: Setting;
    readonly LDWMinAmountSemiMonthly: Setting;
    readonly LDWMinAmountMonthly: Setting;
    readonly LDWMaxAmountWeekly: Setting;
    readonly LDWMaxAmountBiWeekly: Setting;
    readonly LDWMaxAmountSemiMonthly: Setting;
    readonly LDWMaxAmountMonthly: Setting;
    readonly benefitPlusDealerId: Setting;
    readonly RVOnAcceptanceNowTransfer: Setting;
    readonly daysCountedForGracePeriod: Setting;
    readonly agreementReinstatement: Setting;
  };
  readonly inventoryDepreciation: {
    readonly consecutiveIdleDays: Setting;
    readonly waitPeriodForDepreciation: Setting;
    readonly periodOfStraightLineDepreciation: Setting;
    readonly deferredStraightLineDepreciation: Setting;
  };
  readonly fees: {
    readonly rules?: Rules;
    readonly applyProcessingFee: Setting;
    readonly chargeBackFee: Setting;
    readonly cellphone911FeeAmount: Setting;
    readonly NSFFee: Setting;
    readonly payByPhoneFee: Setting;
    readonly daysBeforeLateFeeAppliedMonthly: Setting;
    readonly daysBeforeLateFeeAppliedSemiMonthly: Setting;
    readonly daysBeforeLateFeeAppliedBiWeekly: Setting;
    readonly daysBeforeLateFeeAppliedWeekly: Setting;
    readonly lateFeeAmountMonthly: Setting;
    readonly lateFeeAmountWeekly: Setting;
    readonly recycleFee: Setting;
  };
  readonly payment: {
    readonly SACDaysPrintedOnAgreement: Setting;
    readonly daysUsedToAllowSACOnAgreementPayment: Setting;
    readonly EPOPercent: Setting;
    readonly printEPOHistory: Setting;
    readonly referralRentalPaymentCount: Setting;
    readonly sameAsCashDaysPrinted: Setting;
    readonly sameAsCashDays: Setting;
    readonly totalCostLimit: Setting;
    readonly allowFinalPaymentOnline: Setting;
    readonly allowFinalPaymentAutopay: Setting;
  };
}

const selectOptions: SelectFieldOption[] = [
  { label: '0', value: '0' },
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '130', value: '130' }
];

const LWDMinMaxCommonConfig = {
  rules: {
    required: {
      message: requiredMessage
    },
    min: { message: 'Min value should be bigger then 0' },
    max: {
      message: 'Max value exceeds 99.99'
    }
  },
  autonumericOptions: {
    minimumValue: 0,
    maximumValue: 100,
    decimalPlaces: 2,
    currencySymbol: '$'
  }
};

export const settings: EditConfigsFormSettings = {
  general: {
    LDWAllowedOnFinalPayment: {
      label: 'LDW allowed on final payment',
      name: 'general.LDWAllowedOnFinalPayment'
    },
    benefitPlusDealerId: {
      label: 'Benefit Plus Dealer ID',
      name: 'general.benefitPlusDealerId',
      placeholder: 'Benefit Plus Dealer ID',
      rules: {
        required: {
          message: requiredMessage
        }
      }
    },
    LDWMinAmountWeekly: {
      label: 'Weekly',
      name: 'general.LDWMinAmountWeekly',
      placeholder: 'LWD Rate Weekly',
      ...LWDMinMaxCommonConfig
    },
    LDWMaxAmountWeekly: {
      label: 'Weekly',
      name: 'general.LDWMaxAmountWeekly',
      placeholder: 'LWD Rate Weekly',
      ...LWDMinMaxCommonConfig
    },
    LDWMinAmountBiWeekly: {
      label: 'Bi-Weekly',
      name: 'general.LDWMinAmountBiWeekly',
      placeholder: 'LWD Rate Bi-Weekly',
      ...LWDMinMaxCommonConfig
    },
    LDWMaxAmountBiWeekly: {
      label: 'Bi-Weekly',
      name: 'general.LDWMaxAmountBiWeekly',
      placeholder: 'LWD Rate Bi-Weekly',
      ...LWDMinMaxCommonConfig
    },
    LDWMinAmountSemiMonthly: {
      label: 'Semi-Monthly',
      name: 'general.LDWMinAmountSemiMonthly',
      placeholder: 'LWD Rate Semi-Monthly',
      ...LWDMinMaxCommonConfig
    },
    LDWMaxAmountSemiMonthly: {
      label: 'Semi-Monthly',
      name: 'general.LDWMaxAmountSemiMonthly',
      placeholder: 'LWD Rate Semi-Monthly',
      ...LWDMinMaxCommonConfig
    },
    LDWMinAmountMonthly: {
      label: 'Monthly',
      name: 'general.LDWMinAmountMonthly',
      placeholder: 'LWD Rate Monthly',
      ...LWDMinMaxCommonConfig
    },
    LDWMaxAmountMonthly: {
      label: 'Monthly',
      name: 'general.LDWMaxAmountMonthly',
      placeholder: 'LWD Rate Monthly',
      ...LWDMinMaxCommonConfig
    },
    RVOnAcceptanceNowTransfer: {
      label: 'RV % on Acceptance Now Transfers',
      name: 'general.RVOnAcceptanceNowTransfer',
      placeholder: 'RV % on Acceptance Now Transfers'
    },
    daysCountedForGracePeriod: {
      label: 'Days counted for Grace Period',
      name: 'general.daysCountedForGracePeriod',
      placeholder: 'Days counted for Grace Period',
      rules: {
        required: {
          message: requiredMessage
        },
        max: {
          message: 'Max value exceeds 10 characters'
        }
      }
    },
    agreementReinstatement: {
      label: 'Agreement Reinstatement',
      name: 'general.agreementReinstatement'
    }
  },
  inventoryDepreciation: {
    consecutiveIdleDays: {
      label:
        'Consecutive Idle days before straightline depreciation starts for items on Aged Idle method',
      name: 'inventoryDepreciation.consecutiveIdleDays',
      selectOptions
    },
    waitPeriodForDepreciation: {
      label: 'Wait period for depreciation for items on Aged Idle method',
      name: 'inventoryDepreciation.waitPeriodForDepreciation',
      selectOptions
    },
    periodOfStraightLineDepreciation: {
      label:
        'Period for straight line depreciation for items on Aged Idle method',
      name: 'inventoryDepreciation.periodOfStraightLineDepreciation',
      selectOptions
    },
    deferredStraightLineDepreciation: {
      label: 'Deferred Straightline Depreciation',
      name: 'inventoryDepreciation.deferredStraightLineDepreciation',
      placeholder: 'Deferred Straightline Depreciation',
      rules: {
        required: {
          message: 'Value is required'
        },
        max: { message: 'Max value exceeds 6 characters' }
      }
    }
  },
  fees: {
    rules: {
      min: {
        message: requiredMessage
      },
      max: {
        message: 'You are over the legal limit of 25%'
      }
    },
    applyProcessingFee: {
      label: '',
      name: 'fees.applyProcessingFee',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 100,
        decimalPlaces: 4,
        currencySymbol: '%',
        currencySymbolPlacement: CurrencyPlacement.suffix
      }
    },
    cellphone911FeeAmount: {
      label: '',
      name: 'fees.cellphone911FeeAmount',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 100,
        decimalPlaces: 4,
        currencySymbol: '%',
        currencySymbolPlacement: CurrencyPlacement.suffix
      }
    },
    chargeBackFee: {
      label: '',
      name: 'fees.chargeBackFee',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 999.99,
        decimalPlaces: 4,
        currencySymbol: '$'
      }
    },
    NSFFee: {
      label: '',
      name: 'fees.NSFFee',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 999.99,
        decimalPlaces: 4,
        currencySymbol: '$'
      }
    },
    payByPhoneFee: {
      label: '',
      name: 'fees.payByPhoneFee',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 999.99,
        decimalPlaces: 4,
        currencySymbol: '$'
      }
    },
    daysBeforeLateFeeAppliedMonthly: {
      label: 'Monthly sched.',
      name: 'fees.daysBeforeLateFeeAppliedMonthly',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 999,
        decimalPlaces: 0
      }
    },
    daysBeforeLateFeeAppliedSemiMonthly: {
      label: 'Semi-monthly sched.',
      name: 'fees.daysBeforeLateFeeAppliedSemiMonthly',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 999,
        decimalPlaces: 0
      }
    },
    daysBeforeLateFeeAppliedBiWeekly: {
      label: 'Bi-weekly sched.',
      name: 'fees.daysBeforeLateFeeAppliedBiWeekly',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 999,
        decimalPlaces: 0
      }
    },
    daysBeforeLateFeeAppliedWeekly: {
      label: 'Weekly sched.',
      name: 'fees.daysBeforeLateFeeAppliedWeekly',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 999,
        decimalPlaces: 0
      }
    },
    lateFeeAmountMonthly: {
      label: 'Monthly sched.',
      name: 'fees.lateFeeAmountMonthly',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 99999,
        decimalPlaces: 4,
        currencySymbol: '%',
        currencySymbolPlacement: CurrencyPlacement.suffix
      }
    },
    lateFeeAmountWeekly: {
      label: 'Weekly sched.',
      name: 'fees.lateFeeAmountWeekly',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 99999,
        decimalPlaces: 4,
        currencySymbol: '%',
        currencySymbolPlacement: CurrencyPlacement.suffix
      }
    },
    recycleFee: {
      label: 'Recycle fee',
      name: 'fees.recycleFee'
    }
  },
  payment: {
    SACDaysPrintedOnAgreement: {
      label: 'SAC Days Printed on agreement, receipt',
      name: 'payment.SACDaysPrintedOnAgreement',
      placeholder: 'SAC Days Printed on agreement, receipt',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 100,
        decimalPlaces: 0,
        currencySymbol: '$'
      },
      rules: {
        min: {
          message: 'Value is required and should be greater than 0'
        },
        max: {
          message: 'You are over the legal limit of $25'
        }
      }
    },
    daysUsedToAllowSACOnAgreementPayment: {
      label: 'Days used to allow SAC on agreement payment',
      name: 'payment.daysUsedToAllowSACOnAgreementPayment',
      placeholder: 'Days used to allow SAC on agreement payment',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 1,
        decimalPlaces: 4,
        currencySymbol: '%',
        currencySymbolPlacement: CurrencyPlacement.suffix
      },
      rules: {
        min: {
          message: requiredMessage
        },
        max: {
          message: 'You are over the legal limit of 0.5'
        }
      }
    },
    EPOPercent: {
      label: 'EPO Percent',
      name: 'payment.EPOPercent',
      placeholder: 'EPO Percent',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 1,
        decimalPlaces: 4,
        currencySymbol: '%',
        currencySymbolPlacement: CurrencyPlacement.suffix
      },
      rules: {
        min: {
          message: requiredMessage
        },
        max: {
          message: 'You are over the legal limit of 0.6%'
        }
      }
    },
    printEPOHistory: {
      label: 'Print EPO History',
      name: 'payment.printEPOHistory',
      placeholder: 'Print EPO History',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 1,
        decimalPlaces: 4,
        currencySymbol: '%',
        currencySymbolPlacement: CurrencyPlacement.suffix
      },
      rules: {
        min: {
          message: requiredMessage
        },
        max: {
          message: 'You are over the legal limit of 0.7%'
        }
      }
    },
    referralRentalPaymentCount: {
      label: 'Referral Rental Payment Count',
      name: 'payment.referralRentalPaymentCount',
      placeholder: 'Referral Rental Payment Count',
      autonumericOptions: {
        minimumValue: 0,
        maximumValue: 1,
        decimalPlaces: 0,
        currencySymbol: '',
        currencySymbolPlacement: CurrencyPlacement.suffix
      },
      rules: {
        min: {
          message: requiredMessage
        },
        max: {
          message: 'You are over the legal limit of 0.8%'
        }
      }
    },
    sameAsCashDaysPrinted: {
      label: 'Same As Cash Days Printed',
      name: 'payment.sameAsCashDaysPrinted'
    },
    sameAsCashDays: {
      label: 'Same As Cash Days',
      name: 'payment.sameAsCashDays'
    },
    totalCostLimit: {
      label: 'Total Cost Limit',
      name: 'payment.totalCostLimit'
    },
    allowFinalPaymentOnline: {
      label: 'Allow final payment online',
      name: 'payment.allowFinalPaymentOnline'
    },
    allowFinalPaymentAutopay: {
      label: 'Allow final payment autopay',
      name: 'payment.allowFinalPaymentAutopay'
    }
  }
};
