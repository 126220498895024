import React from 'react';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';

import styles from '../../../../EditConfigurationsForm.module.scss';

import { TooltipItem } from '../../../../../../common/TooltipItem';
import { AutoNumeric } from '../../../../../../common/form/auto-numeric/AutoNumeric';
import { ErrorIcon } from '../../../../../../common/form/error-icon/ErrorIcon';
import { FeeKeys } from '../../../../../../../domain/Configuration/ConfigurationKeys';
import { useRuleField } from '../../../../useRuleField';
import { StateFeesProps } from '../StateFees';

export const BeforeLateFeeApplied = (props: StateFeesProps) => {
  const { currentStateAbbr, disabled } = props;

  const weekly = useRuleField(
    `${currentStateAbbr}.${FeeKeys.GracePeriodWeeklyINote}`
  );
  const biWeekly = useRuleField(
    `${currentStateAbbr}.${FeeKeys.GracePeriodBiWeeklyINote}`
  );
  const semiMonthly = useRuleField(
    `${currentStateAbbr}.${FeeKeys.GracePeriodSemiMonthlyINote}`
  );
  const monthly = useRuleField(
    `${currentStateAbbr}.${FeeKeys.GracePeriodMonthlyINote}`
  );

  return (
    <div className={styles.tableResponsive}>
      <table className={clsx([styles.table])}>
        <thead>
          <tr>
            <th scope="col">
              <span className={styles.label}>Weekly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Bi-weekly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Semi-monthly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Monthly</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <TooltipItem
                title={weekly.fieldError?.message}
                arrow
                placement="bottom-start"
                TransitionProps={{ exit: false }}
              >
                <Controller
                  as={AutoNumeric}
                  name={weekly.fieldName}
                  options={weekly.options}
                  rules={weekly.presentInApi && weekly.rules}
                  size="small"
                  disabled={disabled || !weekly.presentInApi}
                  invalid={!!weekly.fieldError}
                  icon={!!weekly.fieldError && <ErrorIcon />}
                  changed={weekly.changed}
                />
              </TooltipItem>
            </td>
            <td>
              <TooltipItem
                title={biWeekly.fieldError?.message}
                arrow
                placement="bottom-start"
                TransitionProps={{ exit: false }}
              >
                <Controller
                  as={AutoNumeric}
                  name={biWeekly.fieldName}
                  options={biWeekly.options}
                  rules={biWeekly.presentInApi && biWeekly.rules}
                  size="small"
                  disabled={disabled || !biWeekly.presentInApi}
                  invalid={!!biWeekly.fieldError}
                  icon={!!biWeekly.fieldError && <ErrorIcon />}
                  changed={biWeekly.changed}
                />
              </TooltipItem>
            </td>
            <td>
              <TooltipItem
                title={semiMonthly.fieldError?.message}
                arrow
                placement="bottom-start"
                TransitionProps={{ exit: false }}
              >
                <Controller
                  as={AutoNumeric}
                  name={semiMonthly.fieldName}
                  options={semiMonthly.options}
                  rules={semiMonthly.presentInApi && semiMonthly.rules}
                  size="small"
                  disabled={disabled || !semiMonthly.presentInApi}
                  invalid={!!semiMonthly.fieldError}
                  icon={!!semiMonthly.fieldError && <ErrorIcon />}
                  changed={semiMonthly.changed}
                />
              </TooltipItem>
            </td>
            <td>
              <TooltipItem
                title={monthly.fieldError?.message}
                arrow
                placement="bottom-start"
                TransitionProps={{ exit: false }}
              >
                <Controller
                  as={AutoNumeric}
                  name={monthly.fieldName}
                  options={monthly.options}
                  rules={monthly.presentInApi && monthly.rules}
                  size="small"
                  disabled={disabled || !monthly.presentInApi}
                  invalid={!!monthly.fieldError}
                  icon={!!monthly.fieldError && <ErrorIcon />}
                  changed={monthly.changed}
                />
              </TooltipItem>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
