import { intersection } from 'lodash';
import { ConfigurationKeys } from '../domain/Configuration/ConfigurationKeys';

export const errorsForRules = (
  errors: Record<string, Record<string, any>>,
  rules: ConfigurationKeys[]
) => {
  if (!errors) {
    return {};
  }

  const result: Record<string, any> = {};

  Object.entries<Record<string, any>>(errors).forEach(item => {
    const stateAbbreviation = item[0];
    const stateObject = item[1];

    if (intersection(rules, Object.keys(stateObject)).length) {
      result[stateAbbreviation] = stateObject;
    }
  });

  return result;
};
