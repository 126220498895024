import { GenericObject } from './GenericObject';

// https://gist.github.com/penguinboy/762197
export const flatten = (object: any, depth = 2, separator = ':') => {
  const result: any = {};

  for (const i in object) {
    if (typeof object[i] == 'object' && depth > 0) {
      const flatObject = flatten(object[i], depth - 1);
      for (const x in flatObject) {
        result[i + separator + x] = flatObject[x];
      }
    } else {
      result[i] = object[i];
    }
  }
  return result;
};

export const unflatten = (object: GenericObject, separator = ':'): any => {
  const result: GenericObject = {};

  Object.keys(object).forEach((key: string) => {
    const keys = key.split(separator);
    let ref = result;
    for (let i = 0; i < keys.length; i++) {
      ref = ref[keys[i]] =
        i + 1 === keys.length ? object[key] : ref[keys[i]] || {};
    }
  });
  return result;
};
