import React, { useContext } from 'react';
import get from 'lodash/get';
import { CommonProps } from './Fees';
import { FeeKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsStateContext } from '../../../../../context/configurations/configurations-contexts';
import { FieldChangedContext } from '../../FieldChangedContext';
import { Switch } from '../../../../common/form/switch/Switch';

export const ApplyProcessingFee = (props: CommonProps) => {
  const { disabled, configurations } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { fieldChanged } = useContext(FieldChangedContext);
  const fieldName = `COUNTRY.${FeeKeys.applyProcessingFee}`;
  const fieldValue = `${fieldName}.value`;
  const changed = fieldChanged(fieldValue);
  const presentInApi = !!get(initialConfigurations, fieldName);
  return (
    <>
      <Switch
        label="Apply Processing Fee"
        name={fieldValue}
        defaultChecked={get(configurations, fieldValue)}
        disabled={disabled || !presentInApi}
        changed={changed}
        isSeparatorVisible={false}
        isValueVisible
      />
    </>
  );
};
