import { Store } from '../../../domain/Store/Store';

export const getUniqueNumberOfStates = (stores: Store[]): number => {
  const trackedStates: string[] = [];

  return stores.reduce((acc: number, s: Store) => {
    if (!trackedStates.includes(s.state.abbreviation)) {
      trackedStates.push(s.state.abbreviation);
      return acc + 1;
    }
    return acc;
  }, 0);
};
