import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { Redirect } from 'react-router';

import { ScrollableContent } from '../common/scrollable-content/ScrollableContent';
import { SelectionPreviewCollapse } from '../select-stores/selection-preview-collapse/SelectionPreviewCollapse';
import { SelectionPreview } from '../select-stores/selection-preview/SelectionPreview';
import { Footer } from '../layout/footer/Footer';
import { ConfigBackButton } from './ConfigBackButton';
import { EditConfigsNextButton } from './EditConfigsNextButton';
import { ConfigurationsRoute } from '../../config/route-config';
import { EditConfigurationsFormContainer } from './form/EditConfigurationsFormContainer';
import { SelectedStoresSelectorsContext } from '../../context/configurations/selected-stores/selected-stores-context';
import { useConfigurationsStateContext } from '../../context/configurations/configurations-contexts';

import styles from './EditConfigs.module.scss';
import { Loading } from '../common/loader/Loading';

export const editConfigsTestId = 'editConfigsTestId';

export const EditConfigs: FC = () => {
  const selectedStoresSelectors = useContext(SelectedStoresSelectorsContext);

  const selectedStoresExist = selectedStoresSelectors.arePresent();

  const [isCollapseHidden, setIsCollapseHidden] = useState(false);

  const { initialConfigurations } = useConfigurationsStateContext();
  const { isPending } = initialConfigurations;

  const toggleCollapse = useCallback(() => {
    setIsCollapseHidden(!isCollapseHidden);
  }, [isCollapseHidden]);

  return useMemo(
    () => (
      <>
        {selectedStoresExist ? (
          <div
            className={styles.sectionWrapper}
            data-testid={editConfigsTestId}
          >
            <Box width="100%">
              <ScrollableContent subtractFromHeight={{ xs: '127', lg: '297' }}>
                {isPending && <Loading />}
                <EditConfigurationsFormContainer
                  isCollapseHidden={isCollapseHidden}
                />
              </ScrollableContent>
            </Box>

            <SelectionPreviewCollapse
              onHandleClick={toggleCollapse}
              isCollapsed={isCollapseHidden}
            >
              <SelectionPreview subtractFromHeight={{ xs: '127', lg: '362' }} />
            </SelectionPreviewCollapse>

            <Footer>
              <ConfigBackButton path={ConfigurationsRoute.Select} />
              <EditConfigsNextButton />
            </Footer>
          </div>
        ) : (
          <Redirect to={ConfigurationsRoute.Select} />
        )}
      </>
    ),
    [selectedStoresExist, isPending, isCollapseHidden, toggleCollapse]
  );
};
