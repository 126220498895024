import { isArray, merge, mergeWith } from 'lodash';

import { Configurations } from '../../../domain/Configuration/Configurations';
import { ChangeConfigurationsActionType } from './changed-configurations-actions';
import { ConfigurationsAction } from '../configurations-reducer';
import { SelectedStoresActionType } from '../selected-stores/selected-stores-actions';

export const changedConfigurationsReducer = (
  state: Configurations | undefined,
  action: ConfigurationsAction
): Configurations | undefined => {
  switch (action.type) {
    case SelectedStoresActionType.SELECT_STORES:
    case SelectedStoresActionType.DESELECT_STORES:
    case SelectedStoresActionType.SET_INITIAL_STATE:
      return undefined;

    case ChangeConfigurationsActionType.CHANGE_CONFIGURATIONS: {
      const { configurations, isTouched } = action.payload;
      if (state && isTouched) {
        return mergeWith({}, state, configurations, (dest: any, src: any) => {
          if (isArray(dest)) {
            return src;
          }
        });
      } else if (state) {
        return merge({}, state);
      } else {
        return configurations;
      }
    }

    default:
      return state;
  }
};
