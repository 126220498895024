import React, { useReducer, ReactNode, useMemo } from 'react';

import { StoresThunks, getStoresThunk } from './stores-thunks';
import {
  StoresSelectorsContext,
  StoresStateContext,
  StoresThunksContext
} from './stores-context';
import {
  StoresSelectors,
  getStoresByEntity,
  getStoresByState
} from './stores-selectors';
import { initialState, storesReducer } from './stores-reducer';

export const StoresProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(storesReducer, initialState);

  const thunks: StoresThunks = useMemo(
    () => ({
      getStores: getStoresThunk(dispatch)
    }),
    []
  );

  const selectors: StoresSelectors = {
    getStoresByEntity: getStoresByEntity(state.stores),
    getStoresByState: getStoresByState(state.stores)
  };

  return (
    <StoresStateContext.Provider value={state}>
      <StoresSelectorsContext.Provider value={selectors}>
        <StoresThunksContext.Provider value={thunks}>
          {props.children}
        </StoresThunksContext.Provider>
      </StoresSelectorsContext.Provider>
    </StoresStateContext.Provider>
  );
};
