import React from 'react';

import styles from './SelectionPreviewEmpty.module.scss';

export const headingText = 'No selection made';
export const paragraphText =
  'Please select an entry from the list on the left to populate the list';

export const selectionPreviewEmptyTestId = 'selectionPreviewEmptyTestId';

export const SelectionPreviewEmpty = () => {
  return (
    <div
      className={styles.selectionPreviewEmpty}
      data-testid={selectionPreviewEmptyTestId}
    >
      <div className={styles.selectionPreviewContentEmpty}>
        <div className={styles.selectionPreviewContentInnerEmpty}>
          <h3>{headingText}</h3>
          <p>{paragraphText}</p>
        </div>
      </div>
    </div>
  );
};
