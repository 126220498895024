import {
  every,
  get,
  isArray,
  isEmpty,
  isEqual,
  isObject,
  isString,
  map,
  set,
  transform
} from 'lodash';

import { flatten, unflatten } from '../utils/object-flatten';
import { StateAbbreviation } from '../domain/Store/State';
import { Configurations } from '../domain/Configuration/Configurations';
import {
  ConfigurationKeys,
  FeeKeys,
  FixedAmountLateFeeKeys,
  GeneralKeys,
  PaymentKeys
} from '../domain/Configuration/ConfigurationKeys';
import { GenericObject } from '../utils/GenericObject';

/**
 * apiConfigs -> .flattenRules -> .coerceValues -> form ⬇
 * .extractChanges -> .fillRulesWithExtraData -> .formatValues -> .unflattenRules -> apiConfigs
 */

type Coercions = {
  [key in ConfigurationKeys]?: (value: any) => boolean | object | undefined;
};

const from1 = (value: '1' | '0') => ({ '1': true, '0': false }[value]);
const fromY = (value: 'Y' | 'N') => ({ Y: true, N: false }[value]);
const fromTRUE = (value: 'TRUE' | 'FALSE') =>
  ({ TRUE: true, FALSE: false }[value]);
const fromTrue = (value: 'true' | 'false') =>
  ({ true: true, false: false }[value]);
const fromJSON = (value: string) => JSON.parse(value);

const coercions: Coercions = {
  [GeneralKeys.LDWAllowedOnFinalPayment]: from1,
  [GeneralKeys.AgreementReinstatement]: fromTRUE,
  [GeneralKeys.TiresAndOffered]: fromTRUE,
  [FeeKeys.applyProcessingFee]: from1,
  [FeeKeys.recycleFee]: fromTrue,
  [FixedAmountLateFeeKeys.MultipleAllowed]: from1,
  [PaymentKeys.PrintEPOHistory]: fromY,
  [PaymentKeys.SameAsCashDays]: fromTRUE,
  [PaymentKeys.SameAsCashDaysPrinted]: fromY,
  [PaymentKeys.AllowFinalPaymentOnline]: fromY,
  [PaymentKeys.AllowFinalPaymentAutopay]: fromY,
  [PaymentKeys.SACDaysPrintedOnAgreement]: fromJSON,
  [PaymentKeys.DaysUsedToAllowSACOnAgreementPayment]: fromJSON
};

type BooleanFormat = (value: boolean | undefined) => string;
type JsonFormat = (value: object | undefined) => string;
type Formatters = {
  [key in ConfigurationKeys]?: BooleanFormat | JsonFormat;
};

const to1 = (value: boolean | undefined): string =>
  value === undefined ? '' : value ? '1' : '0';
const toY = (value: boolean | undefined) =>
  value === undefined ? '' : value ? 'Y' : 'N';
const toTRUE = (value: boolean | undefined) =>
  value === undefined ? '' : value ? 'TRUE' : 'FALSE';
const toTrue = (value: boolean | undefined) =>
  value === undefined ? '' : value ? 'true' : 'false';
const toJSON = (value: object | undefined) => JSON.stringify(value || {});

const formatters: Formatters = {
  [GeneralKeys.LDWAllowedOnFinalPayment]: to1,
  [GeneralKeys.AgreementReinstatement]: toTRUE,
  [GeneralKeys.TiresAndOffered]: toTRUE,
  [FeeKeys.applyProcessingFee]: to1,
  [FeeKeys.recycleFee]: toTrue,
  [FixedAmountLateFeeKeys.MultipleAllowed]: to1,
  [PaymentKeys.PrintEPOHistory]: toY,
  [PaymentKeys.SameAsCashDays]: toTRUE,
  [PaymentKeys.SameAsCashDaysPrinted]: toY,
  [PaymentKeys.AllowFinalPaymentOnline]: toY,
  [PaymentKeys.AllowFinalPaymentAutopay]: toY,
  [PaymentKeys.SACDaysPrintedOnAgreement]: toJSON,
  [PaymentKeys.DaysUsedToAllowSACOnAgreementPayment]: toJSON
};

export const isValidStateKey = (key: string) => {
  return [...Object.values(StateAbbreviation), 'COUNTRY'].includes(key);
};

const forEachRule = (
  configurations: Configurations,
  callback: (
    key: StateAbbreviation | 'COUNTRY',
    ruleName: ConfigurationKeys
  ) => any
) => {
  Object.keys(configurations).forEach(k => {
    const key = k as StateAbbreviation | 'COUNTRY';
    if (!isValidStateKey(key)) {
      return;
    }

    Object.keys(configurations[key] || {}).forEach(ruleName => {
      callback(key, ruleName as ConfigurationKeys);
    });
  });
};

export const flattenRules = async (
  apiConfigs: any
): Promise<Configurations> => {
  Object.keys(apiConfigs).forEach(key => {
    apiConfigs[key] = flatten(apiConfigs[key]);
  });

  return apiConfigs as Configurations;
};

export const unflattenRules = (
  configurations: Configurations
): GenericObject => {
  Object.keys(configurations).forEach((k: any) => {
    const key = k as StateAbbreviation | 'COUNTRY';
    const configuration = configurations[key];
    if (configuration) {
      configurations[key] = unflatten(configuration);
    }
  });

  return configurations;
};

export const coerceValues = (
  configurations: Configurations
): Configurations => {
  forEachRule(configurations, (key, ruleName) => {
    const coercionFn = coercions[ruleName];
    if (coercionFn) {
      const path = `${key}.${ruleName}.value`;

      set(configurations, path, coercionFn(get(configurations, path)));
    }
  });

  return configurations;
};

export const formatValues = (
  configurations: Configurations
): Configurations => {
  forEachRule(configurations, (key, ruleName) => {
    const formatFn = formatters[ruleName];
    if (formatFn) {
      const path = `${key}.${ruleName}.value`;

      set(configurations, path, formatFn(get(configurations, path)));
    }
  });

  return configurations;
};

export const fillRulesWithExtraData = (
  current: Configurations,
  changes: Configurations,
  initialConfigurations?: Configurations
): Configurations => {
  forEachRule(changes, (key, ruleName) => {
    const path = `${key}.${ruleName}`;
    const currentValue = get(current, `${path}.value`);

    set(changes, path, {
      ...get(initialConfigurations, path),
      value: currentValue
    });
  });

  return changes;
};

function deepEmpty(object: any): boolean {
  if (isObject(object)) {
    if (isEmpty(object)) return true;
    return every(map(object, v => deepEmpty(v)));
  } else if (
    object === undefined ||
    object === null ||
    (isString(object) && !object.length)
  ) {
    return true;
  }
  return false;
}

/**
 * Source: https://gist.github.com/Yimiprod/7ee176597fef230d1451
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object representing the positive changes
 */
export function extractChanges(object: GenericObject, base?: GenericObject) {
  function changes(object: any, base: any) {
    return transform(object, function(result: any, value, key) {
      if (!isEqual(value, base[key]) && value !== undefined) {
        if (!isArray(value) && isObject(value) && isObject(base[key])) {
          result[key] = changes(value, base[key]);
        } else if (isArray(value) || !isObject(value) || !deepEmpty(value)) {
          result[key] = value;
        }
      }
    });
  }
  return changes(object, base);
}
