import React, { useContext } from 'react';
import clsx from 'clsx';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';

import tableStyles from '../../EditConfigurationsForm.module.scss';
import styles from './General.module.scss';

import { GeneralKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsSelectorsContext } from '../../../../../context/configurations/configurations-contexts';
import { ConfigurationGroupTitle } from '../../../../../config/edit-configs-form-settings';
import { InputField } from '../../../../common/form/input-field/InputField';
import { ComponentProps } from '../../EditConfigurationsForm';
import { FieldChangedContext } from '../../FieldChangedContext';
import { LDW } from './LDW/LDW';
import { CollapsableCard } from '@rentacenter/racstrap';
import { AgreementReinstatement } from './AgreementReinstatement';
import { TiresAndOffered } from './TiresAndOffered';
import { RVOnAcceptanceNowTransfer } from './RVOnAcceptanceNowTransfer';
import { InputHelp } from '../../../../common/form/input-help/InputHelp';

export const collapsibleCardTestId = 'collapsibleCardTestId';

export const General = (props: ComponentProps) => {
  const { isCollapseHidden, disabled, configurations } = props;
  const { control, errors } = useFormContext();
  const { fieldChanged } = useContext(FieldChangedContext);
  const { countUniqRules } = useConfigurationsSelectorsContext();

  const daysCountedForGracePeriodChanged = fieldChanged(
    `COUNTRY.${GeneralKeys.GracePeriodBusinessDays}.value`
  );

  const daysCountedForGracePeriodFieldError = get(
    errors,
    `COUNTRY.${GeneralKeys.GracePeriodBusinessDays}.value`
  );

  return (
    <CollapsableCard
      className={styles.card}
      title={`${ConfigurationGroupTitle.general} (${countUniqRules(
        Object.values(GeneralKeys)
      )})`}
      expanded={disabled}
      data-testid={collapsibleCardTestId}
    >
      <Grid container spacing={3}>
        <Grid item lg={isCollapseHidden ? 9 : 12}>
          <LDW disabled={disabled} configurations={configurations} />
        </Grid>
        <Grid item lg={7}>
          <div className={tableStyles.tableResponsive}>
            <table className={clsx(tableStyles.table, tableStyles.alignLeft)}>
              <thead>
                <tr>
                  <th scope="col">
                    <span className={tableStyles.label}>
                      RV % on Acima Transfers{' '}
                      <InputHelp
                        text="Percentage of inventory Remaining
                        Value that will be transferred to the store’s
                        financial."
                      />
                    </span>
                  </th>
                  <th scope="col">
                    <span className={tableStyles.label}>
                      Days counted for Grace Period{' '}
                      <InputHelp
                        text="Calculate Grace Period for a
                        late customer while taking into account
                         days the store is open (BUSINESSDAYS) or
                         all days of the week (ALLDAYS)."
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.inputsRow}>
                  <td className={styles.inputCell}>
                    <RVOnAcceptanceNowTransfer />
                  </td>
                  <td className={styles.inputCell}>
                    <Controller
                      as={InputField}
                      control={control}
                      name={`COUNTRY.${GeneralKeys.GracePeriodBusinessDays}.value`}
                      placeholder="Days counted for Grace Period"
                      size="small"
                      invalid={!!daysCountedForGracePeriodFieldError}
                      errorMessage={
                        daysCountedForGracePeriodFieldError?.message
                      }
                      disabled
                      changed={daysCountedForGracePeriodChanged}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Grid container>
            <Grid item lg={8}>
              <AgreementReinstatement
                disabled={disabled}
                configurations={configurations}
              />
              <TiresAndOffered
                disabled={disabled}
                configurations={configurations}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CollapsableCard>
  );
};
