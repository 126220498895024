import {
  selectedStoresInitialState,
  selectedStoresReducer,
  SelectedStoresState
} from './selected-stores/selected-stores-reducer';
import {
  initialConfigurationsDefaultState,
  initialConfigurationsReducer,
  InitialConfigurationsState
} from './initial-configurations/initial-configurations-reducer';
import { changedConfigurationsReducer } from './changed-configurations/changed-configurations-reducer';
import { Configurations } from '../../domain/Configuration/Configurations';
import { ApiAction } from '../api-actions';
import { ChangeConfigurationsAction } from './changed-configurations/changed-configurations-actions';
import { SelectedStoreAction } from './selected-stores/selected-stores-actions';
import { InitialConfigurationsAction } from './initial-configurations/initial-configurations-actions';
import {
  SubmitConfigurationsState,
  initialState,
  submitConfigurationsReducer
} from './submit-configurations-reducer';

export type ConfigurationsAction =
  | ChangeConfigurationsAction
  | SelectedStoreAction
  | InitialConfigurationsAction
  | ApiAction;

export interface ConfigurationsState {
  readonly selectedStores: SelectedStoresState;
  readonly initialConfigurations: InitialConfigurationsState;
  readonly changedConfigurations?: Configurations;
  readonly submitConfigurations: SubmitConfigurationsState;
}

export const configurationsInitialState: ConfigurationsState = {
  selectedStores: selectedStoresInitialState,
  initialConfigurations: initialConfigurationsDefaultState,
  submitConfigurations: initialState
};

export const configurationsReducer = (
  state: ConfigurationsState,
  action: ConfigurationsAction
): ConfigurationsState => {
  const {
    selectedStores,
    initialConfigurations,
    changedConfigurations,
    submitConfigurations
  } = state;

  return {
    selectedStores: selectedStoresReducer(selectedStores, action),
    initialConfigurations: initialConfigurationsReducer(
      initialConfigurations,
      action
    ),
    changedConfigurations: changedConfigurationsReducer(
      changedConfigurations,
      action
    ),
    submitConfigurations: submitConfigurationsReducer(
      submitConfigurations,
      action
    )
  };
};
