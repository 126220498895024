import React from 'react';
import clsx from 'clsx';
import { Label, Select as RacSelect, SelectProps } from '@rentacenter/racstrap';

import styles from './Select.module.scss';
import { InputHelp } from '../input-help/InputHelp';

interface Props extends SelectProps {
  changed?: boolean;
  tooltip?: React.ReactNode;
}

export const Select = ({ changed, label, tooltip, ...rest }: Props) => (
  <>
    {label && (
      <Label className={styles.label}>
        {label} {tooltip && <InputHelp text={tooltip} />}
      </Label>
    )}

    <RacSelect
      label=""
      classes={{
        select: styles.select,
        selection: clsx(changed && styles.changed),
        options: styles.options
      }}
      {...rest}
    />
  </>
);
