import React, { useContext } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import { AutoNumeric } from '../../../../common/form/auto-numeric/AutoNumeric';
import { FeeKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsValidationContext } from '../../../../../context/configurations/configurations-contexts';
import { FieldChangedContext } from '../../FieldChangedContext';
import { CommonProps } from './Fees';

export const FeeAmount = (props: CommonProps) => {
  const { configurations, disabled } = props;
  const { errors } = useFormContext();
  const validation = useConfigurationsValidationContext();
  const { fieldChanged } = useContext(FieldChangedContext);
  const applyProcessingFee = `COUNTRY.${FeeKeys.applyProcessingFee}.value`;
  const applyProcessingFeeValue = useWatch({ name: applyProcessingFee });
  const feeAmountName = `COUNTRY.${FeeKeys.FeeAmount}`;
  const feeAmountValue = `${feeAmountName}.value`;
  const changed = fieldChanged(feeAmountValue);
  const fieldError = get(errors, feeAmountValue);
  const presentInApi = !!get(configurations, feeAmountName);
  const { options, ...rules } = get(validation, feeAmountValue);

  return (
    <>
      <Controller
        as={AutoNumeric}
        name={feeAmountValue}
        options={options}
        rules={presentInApi && applyProcessingFeeValue && rules}
        label="Fee Amount"
        ariaLabelledby={FeeKeys.FeeAmount}
        size="small"
        invalid={applyProcessingFeeValue && !!fieldError}
        disabled={!presentInApi || !applyProcessingFeeValue || disabled}
        changed={changed}
        errorMessage={applyProcessingFeeValue && fieldError?.message}
      />
    </>
  );
};
