import React from 'react';
import clsx from 'clsx';

import formStyles from '../../../EditConfigurationsForm.module.scss';
import typographyStyles from '../../typography.module.scss';

import { Configurations } from '../../../../../../domain/Configuration/Configurations';
import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { InputListTitle } from '../../../../../../config/edit-configs-form-settings';
import { Cellphone911FeeAmount } from './Cellphone911FeeAmount';
import { ChargeBackFee } from './ChargeBackFee';
import { NSFFee } from './NSFFee';
import { PayByPhoneFee } from './PayByPhoneFee';
import { DaysBeforeLateFeeApplied } from './DaysBeforeLateFeeApplied/DaysBeforeLateFeeApplied';
import { BeforeLateFeeApplied } from './InstallmentNoteDays/BeforeLateFeeApplied';
import { LateFeeAmount } from './LateFee/LateFeeAmount';
import { RecycleFee } from './RecycleFee';

export interface StateFeesProps {
  readonly currentStateAbbr: StateAbbreviation;
  readonly configurations: Configurations;
  readonly disabled?: boolean;
}

export const StateFees = (props: StateFeesProps) => {
  const { currentStateAbbr } = props;
  return (
    <>
      <h1 className={typographyStyles.sectionTitle}>Fee Section</h1>
      <div className={formStyles.tableResponsive}>
        <table className={clsx(formStyles.table, formStyles.alignLeft)}>
          <thead>
            <tr>
              <th scope="col">
                <span className={formStyles.label}>
                  {InputListTitle.cellphone911FeeAmount}
                </span>
              </th>
              <th scope="col">
                <span className={formStyles.label}>
                  {InputListTitle.chargeBackFee}
                </span>
              </th>
              <th scope="col">
                <span className={formStyles.label}>
                  {InputListTitle.NSFFee}
                </span>
              </th>
              <th scope="col">
                <span className={formStyles.label}>
                  {InputListTitle.payByPhoneFee}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Cellphone911FeeAmount {...props} />
              </td>
              <td>
                <ChargeBackFee {...props} />
              </td>
              <td>
                <NSFFee {...props} />
              </td>
              <td>
                <PayByPhoneFee {...props} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className={typographyStyles.hr} />
      <h1 className={typographyStyles.sectionTitle}>
        {InputListTitle.daysBeforeLateFeeApplied}
      </h1>
      <DaysBeforeLateFeeApplied {...props} />

      {currentStateAbbr === StateAbbreviation.NC && (
        <>
          <hr className={typographyStyles.hr} />
          <h1 className={typographyStyles.sectionTitle}>
            {InputListTitle.installmentDaysBeforeLateFeeApplied}
          </h1>
          <BeforeLateFeeApplied {...props} />
        </>
      )}

      <hr className={typographyStyles.hr} />
      <h1 className={typographyStyles.sectionTitle}>
        {InputListTitle.lateFeeAmount}
      </h1>
      <LateFeeAmount {...props} />

      <hr className={typographyStyles.hr} />
      <RecycleFee {...props} />
    </>
  );
};
