export enum GeneralKeys {
  LDWRate = 'LDWFee:BoundedPercentLDWFee:LDWRate',
  LDWAllowedOnFinalPayment = 'LDWFee:BoundedPercentLDWFee:IsLDWAllowedOnFinalPayment',
  LDWMinimumAmountWeekly = 'LDWFee:BoundedPercentLDWFee:LDWMinimumAmountWeekly',
  LDWMinimumAmountBiWeekly = 'LDWFee:BoundedPercentLDWFee:LDWMinimumAmountBiWeekly',
  LDWMinimumAmountSemiMonthly = 'LDWFee:BoundedPercentLDWFee:LDWMinimumAmountSemiMonthly',
  LDWMinimumAmountMonthly = 'LDWFee:BoundedPercentLDWFee:LDWMinimumAmountMonthly',
  LDWMaxAmountWeekly = 'LDWFee:BoundedPercentLDWFee:LDWMaximumAmountWeekly',
  LDWMaxAmountBiWeekly = 'LDWFee:BoundedPercentLDWFee:LDWMaximumAmountBiWeekly',
  LDWMaxAmountSemiMonthly = 'LDWFee:BoundedPercentLDWFee:LDWMaximumAmountSemiMonthly',
  LDWMaxAmountMonthly = 'LDWFee:BoundedPercentLDWFee:LDWMaximumAmountMonthly',
  RentingGroupNumber = 'ClubCoverage:PartyClubCoverage:BenefitPlusClubId',
  RVOnAcceptanceNowTransfer = 'RvValuePct:RvValuePercent:RvValueStorePct',
  GracePeriodBusinessDays = 'GracePeriod:FixedGracePeriod:GracePeriodBusinessDays',
  AgreementReinstatement = 'Reinstatement:ReinstatementStrategy:ReinstatementByState',
  TiresAndOffered = 'SubSystemAccess:UseAgmPolicy:EnableAccess',
  RecycleFee = 'RecycleFee:RMSBasedRecycleFeeStrategy:RMSBasedRecycleFee'
}
export enum InventoryDepreciationKeys {
  ConsecutiveWaitDaysForSwitch = 'Depreciation:AgedIdleDepr:ConsecutiveWaitDaysForSwitch',
  InitialWaitPeriod = 'Depreciation:AgedIdleDepr:InitialWaitPeriod',
  RemainingLifeInMonths = 'Depreciation:AgedIdleDepr:RemainingLifeInMonths',
  InitialWaitPeriodDeferred = 'Depreciation:DeferDep:InitialWaitPeriod',
  AppliedDepriciationMethod = 'Depreciation:AppliedDepMethod:depreciationMethodDesc'
}

// Fee keys
export enum FeeKeys {
  applyProcessingFee = 'SubSystemAccess:AppFeeForProcess:EnableAccess',
  FeeAmount = 'SubSystemAccess:AppFeeForProcess:FeeAmount', // new
  ChargeBackFee = 'ChargeBackFee:FixedChargeBackFee:ChargeBackFee',
  PayByPhoneFee = 'PaymentFee:FixedServiceFee:PaymentFee',
  NSFFee = 'NSFFee:FixedNSFFee:NSFFee',
  Cellphone911FeeAmount = 'Cellphone911Fee:BoundedPercentCellphone911Fee:Cellphone911Fee_Percent',
  GracePeriodMonthly = 'GracePeriod:FixedGracePeriod:GracePeriodMonthly',
  GracePeriodSemiMonthly = 'GracePeriod:FixedGracePeriod:GracePeriodSemiMonthly',
  GracePeriodBiWeekly = 'GracePeriod:FixedGracePeriod:GracePeriodBiWeekly',
  GracePeriodWeekly = 'GracePeriod:FixedGracePeriod:GracePeriodWeekly',
  GracePeriodMonthlyINote = 'GracePeriod:FixedGracePeriod:GracePeriodMonthlyINote',
  GracePeriodSemiMonthlyINote = 'GracePeriod:FixedGracePeriod:GracePeriodSemiMonthlyINote',
  GracePeriodBiWeeklyINote = 'GracePeriod:FixedGracePeriod:GracePeriodBiWeeklyINote',
  GracePeriodWeeklyINote = 'GracePeriod:FixedGracePeriod:GracePeriodWeeklyINote',
  recycleFee = 'RecycleFee:RMSBasedRecycleFeeStrategy:RMSBasedRecycleFee'
}

export enum GreaterOfLateFeeKeys {
  Percentage = 'LateFee:GreaterOfLateFeeStrategy:LateFeePercentage',
  TotalCapPercentage = 'LateFee:GreaterOfLateFeeStrategy:TotalLateFeeCapPercentage',
  AmountMonthly = 'LateFee:GreaterOfLateFeeStrategy:LateFeeAmountMonthly',
  AmountWeekly = 'LateFee:GreaterOfLateFeeStrategy:LateFeeAmountWeekly'
}

export enum LesserOfLateFeeKeys {
  Percentage = 'LateFee:LesserOfLateFeeStrategy:LateFeePercentage',
  AmountMonthly = 'LateFee:LesserOfLateFeeStrategy:LateFeeAmountMonthly',
  AmountWeekly = 'LateFee:LesserOfLateFeeStrategy:LateFeeAmountWeekly'
}

export enum LesserOfLateFeeNoteKeys {
  Percentage = 'LateFee:LesserOfLateFeeStrategyNote:LateFeeNotePercentage',
  AmountWeekly = 'LateFee:LesserOfLateFeeStrategyNote:LateFeeAmountWeekly',
  AmountMonthly = 'LateFee:LesserOfLateFeeStrategyNote:LateFeeAmountMonthly',
  InstallmentAmount = 'LateFee:LesserOfLateFeeStrategyNote:LateFeeInstallmentNoteAmount'
}

export enum FixedAmountLateFeeKeys {
  Weekly = 'LateFee:FixedAmountLateFeeStrategy:LateFeeAmountWeekly',
  Monthly = 'LateFee:FixedAmountLateFeeStrategy:LateFeeAmountMonthly',
  MultipleAllowed = 'LateFee:FixedAmountLateFeeStrategy:MultipleLateFeesAllowed'
}

export enum BoundedAmountsLateFeeKeys {
  Weekly1 = 'LateFee:BoundedAmountsLateFeeStrategy:LateFeeAmountWeekly1',
  Weekly2 = 'LateFee:BoundedAmountsLateFeeStrategy:LateFeeAmountWeekly2',
  WeeklyBoundary = 'LateFee:BoundedAmountsLateFeeStrategy:LateFeeAmountWeeklyBoundary',
  Monthly1 = 'LateFee:BoundedAmountsLateFeeStrategy:LateFeeAmountMonthly1'
}

export enum BoundedLesserOfLateFeeKeys {
  LateFeePercentage = 'LateFee:BoundedLesserOfLateFeeStrategy:LateFeePercentage',
  LowerLateFeeBound = 'LateFee:BoundedLesserOfLateFeeStrategy:LowerLateFeeBound',
  UpperLateFeeBound = 'LateFee:BoundedLesserOfLateFeeStrategy:UpperLateFeeBound'
}

type AllFeeKeys =
  | FeeKeys
  | GreaterOfLateFeeKeys
  | LesserOfLateFeeKeys
  | LesserOfLateFeeNoteKeys
  | FixedAmountLateFeeKeys
  | BoundedAmountsLateFeeKeys
  | BoundedLesserOfLateFeeKeys;

export const lateFeeKeys = [
  ...Object.values(GreaterOfLateFeeKeys),
  ...Object.values(LesserOfLateFeeKeys),
  ...Object.values(LesserOfLateFeeNoteKeys),
  ...Object.values(FixedAmountLateFeeKeys),
  ...Object.values(BoundedAmountsLateFeeKeys),
  ...Object.values(BoundedLesserOfLateFeeKeys)
];

export const allFeeKeys = [...Object.values(FeeKeys), ...lateFeeKeys];
// End Fee keys

export enum PaymentKeys {
  SACDaysPrintedOnAgreement = 'SameAsCash:NumberOfDaysStrategy:SameAsCashDaysPrinted',
  DaysUsedToAllowSACOnAgreementPayment = 'SameAsCash:NumberOfDaysStrategy:SameAsCashDays',

  EPOPercentRemainingRent = 'EPO:remainingRentStrategy:EPO_Percent',
  EPOPercentCashPriceRentPaid = 'EPO:cashPriceAndRentPaidStrategy:EPO_Percent',
  EPOCachePriceTotalCostRatio = 'EPO:RemRentAndCashPriceTotalCostRatioStrategy:NoParameter',
  EPOCachePriceAndTerms = 'EPO:cashPriceAndTermsStrategy:NoParameter',
  EPORentPaidAcquisitionCost = 'EPO:RentPaidAndTimePriceDifferentialStrategy:AcquisitionCost',

  PrintEPOHistory = 'Miscellaneous:MiscellaneousIndicatorsStrategy:PrintEPOHistory',
  ReferralRentalPaymentCount = 'Referral:ReferralStrategy:ReferralRentalPaymentCount',
  SameAsCashDaysPrinted = 'AutoPayAndEPayConfig:ShowSACIndicator:ShowSACValueOnPaymentReceipt',
  SameAsCashDays = 'AlwaysUseSameAsCash:AlwaysUseSameAsCash:UseSameAsCash',
  SameAsCashDaysPrintedInputs = 'SameAsCash:NumberOfDaysStrategy:SameAsCashDaysPrinted',
  SameAsCashDaysInputs = 'SameAsCash:NumberOfDaysStrategy:SameAsCashDays',
  TotalCostLimit = 'LegalEngine:MultipleOfCostStrategy:TotalCostLimit',
  AllowFinalPaymentOnline = 'AutoPayAndEPayConfig:FinalPaymentIndicator:AllowFinalPaymentElectronically',
  AllowFinalPaymentAutopay = 'AutoPayAndEPayConfig:FinalPaymentIndicatorAutoPay:AllowFinalPaymentAutoPay'
}

export type ConfigurationKeys =
  | GeneralKeys
  | InventoryDepreciationKeys
  | AllFeeKeys
  | PaymentKeys;
