import React from 'react';
import clsx from 'clsx';
import { Label } from '@rentacenter/racstrap';

import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { Configurations } from '../../../../../../domain/Configuration/Configurations';
import { InputListTitle } from '../../../../../../config/edit-configs-form-settings';
import { LDWAllowedOnFinalPayment } from './LDWAllowedOnFinalPayment';
import { LDWMinAmountWeekly } from './LDWMinAmountWeekly';
import { LDWMinAmountBiWeekly } from './LDWMinAmountBiWeekly';
import { LDWMinAmountSemiMonthly } from './LDWMinAmountSemiMonthly';
import { LDWMinAmountMonthly } from './LDWMinAmountMonthly';
import { LDWMaxAmountWeekly } from './LDWMaxAmountWeekly';
import { LDWMaxAmountBiWeekly } from './LDWMaxAmountBiWeekly';
import { LDWMaxAmountSemiMonthly } from './LDWMaxAmountSemiMonthly';
import { LDWMaxAmountMonthly } from './LDWMaxAmountMonthly';
// import { ClubType } from '../ClubType';

import styles from '../../../EditConfigurationsForm.module.scss';
import LDWStyles from './LDW.module.scss';
import { LDWRate } from './LDWRate';

export interface LWDConfigProps {
  readonly currentStateAbbr: StateAbbreviation;
  readonly configurations: Configurations;
  readonly disabled?: boolean;
  readonly setModalOpen: (value: boolean) => void;
}

export const LDWTabPanel = (props: LWDConfigProps) => {
  const {
    currentStateAbbr,
    configurations,
    disabled
    // setModalOpen
  } = props;

  return (
    <>
      <div className={styles.tableResponsive}>
        {/* <div className={LDWStyles.clubType}>
          <ClubType
            setModalOpen={(value: boolean) => setModalOpen(value)}
            configurations={configurations}
            disabled={disabled}
            currentStateAbbr={currentStateAbbr}
          />
        </div> */}
        <div className={LDWStyles.rate}>
          <Label className={LDWStyles.label}>{InputListTitle.LDWRate}</Label>
          <LDWRate disabled={disabled} currentStateAbbr={currentStateAbbr} />
        </div>
        <table className={clsx(styles.table, styles.alignLeft)}>
          <thead>
            <tr>
              <th scope="col" className={LDWStyles.labelCol} />
              <th scope="col">
                <span className={styles.label}>Weekly</span>
              </th>
              <th scope="col">
                <span className={styles.label}>Bi-Weekly</span>
              </th>
              <th scope="col">
                <span className={styles.label}>Semi-Monthly</span>
              </th>
              <th scope="col">
                <span className={styles.label}>Monthly</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                <span className={styles.labelRight}>Min. amnt.</span>
              </th>
              <td>
                <LDWMinAmountWeekly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
              <td>
                <LDWMinAmountBiWeekly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
              <td>
                <LDWMinAmountSemiMonthly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
              <td>
                <LDWMinAmountMonthly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <span className={styles.labelRight}>Max. amnt.</span>
              </th>
              <td>
                <LDWMaxAmountWeekly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
              <td>
                <LDWMaxAmountBiWeekly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
              <td>
                <LDWMaxAmountSemiMonthly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
              <td>
                <LDWMaxAmountMonthly
                  disabled={disabled}
                  currentStateAbbr={currentStateAbbr}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={LDWStyles.finalPayment}>
        <LDWAllowedOnFinalPayment
          disabled={disabled}
          configurations={configurations}
          currentStateAbbr={currentStateAbbr}
        />
      </div>
    </>
  );
};
