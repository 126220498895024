import React from 'react';
import clsx from 'clsx';
import styles from './Loading.module.scss';
import LoaderImg from '../../../assets/images/spinner.gif';
import { appConfig } from '../../../config/app-config';

interface Props {
  readonly className?: string;
  readonly text?: string;
}

export const Loading = (props: Props) => {
  const { className, text } = props;
  return (
    <div className={clsx(className, styles.loadingContainer)}>
      <img
        className={styles.loadingImg}
        alt="loading"
        src={`${appConfig.microUrl}${LoaderImg}`}
      />
      <h2 className={styles.loadingText}>{text || 'Loading, please wait'}</h2>
    </div>
  );
};
