import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Card } from '@rentacenter/racstrap';

import classes from './SelectStores.module.scss';

import { ScrollableContent } from '../common/scrollable-content/ScrollableContent';
import { StoreListByEntity } from './store-list/StoreListByEntity';
import { SelectionPreview } from './selection-preview/SelectionPreview';
import { useStoresStateContext } from '../../context/stores/stores-context';
import { Loading } from '../common/loader/Loading';

export const selectStoresByEntityTestId = 'selectStoresByEntityTestId';

export const SelectStoresByEntity: FC = () => {
  const { isPending } = useStoresStateContext();
  const { isRenderedByContainer } = window;
  const customHeightOffset = {
    xs: isRenderedByContainer ? '200' : '127',
    lg: isRenderedByContainer ? '380' : '285'
  };

  return (
    <div data-testid={selectStoresByEntityTestId} className={classes.container}>
      <Grid container>
        <Grid item lg={8}>
          <ScrollableContent subtractFromHeight={customHeightOffset}>
            {isPending && <Loading />}
            <StoreListByEntity />
          </ScrollableContent>
        </Grid>
        <Grid item lg={4}>
          <Card className={classes.preview}>
            <SelectionPreview subtractFromHeight={customHeightOffset} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
