import { createContext, Dispatch, useContext } from 'react';

import {
  ConfigurationsAction,
  configurationsInitialState,
  ConfigurationsState
} from './configurations-reducer';
import { ConfigurationsSelectors } from './configurations-selectors';
import { ConfigurationsThunks } from './configurations-thunks';
import { ConfigurationsValidationSchema } from '../../domain/Configuration/Configurations';

export const ConfigurationsStateContext = createContext<ConfigurationsState>(
  configurationsInitialState
);

export const ConfigurationsSelectorsContext = createContext<
  ConfigurationsSelectors
>({} as ConfigurationsSelectors);

export const ConfigurationsValidatorContext = createContext<
  ConfigurationsValidationSchema
>({} as ConfigurationsValidationSchema);

export const ConfigurationsDispatchContext = createContext<
  Dispatch<ConfigurationsAction>
>(() => {});

export const ConfigurationsThunksContext = createContext<ConfigurationsThunks>(
  {} as ConfigurationsThunks
);

export const useConfigurationsValidationContext = (): ConfigurationsValidationSchema =>
  useContext(ConfigurationsValidatorContext);

export const useConfigurationsStateContext = (): ConfigurationsState =>
  useContext(ConfigurationsStateContext);

export const useConfigurationsSelectorsContext = (): ConfigurationsSelectors =>
  useContext(ConfigurationsSelectorsContext);

export const useConfigurationsDispatchContext = (): Dispatch<ConfigurationsAction> =>
  useContext(ConfigurationsDispatchContext);

export const useUserConfigurationsThunksContext = (): ConfigurationsThunks =>
  useContext(ConfigurationsThunksContext);
