import React, { PropsWithChildren } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './Modal.module.scss';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  closable?: boolean;
}

export const Modal = ({
  isOpen,
  children,
  title,
  closable = true,
  onClose
}: PropsWithChildren<ModalProps>) => {
  return (
    <Dialog open={isOpen} classes={{ paper: styles.modal }}>
      <div className={styles.title}>
        {title}{' '}
        {closable && (
          <FontAwesomeIcon
            className={styles.closeBtn}
            icon={faTimes}
            onClick={onClose}
          />
        )}
      </div>
      {children}
    </Dialog>
  );
};

export const ModalContent = ({ children }: PropsWithChildren<{}>) => {
  return <div className={styles.content}>{children}</div>;
};

export const ModalButtons = ({ children }: PropsWithChildren<{}>) => {
  return <div className={styles.buttons}>{children}</div>;
};
