import React from 'react';
import { get } from 'lodash';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { TooltipItem } from '../../../../../common/TooltipItem';
import { AutoNumeric } from '../../../../../common/form/auto-numeric/AutoNumeric';
import { ErrorIcon } from '../../../../../common/form/error-icon/ErrorIcon';
import { BoundedAmountsLateFeeKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsStateContext } from '../../../../../../context/configurations/configurations-contexts';
import { useRuleField } from '../../../useRuleField';
import { LateFeeCommonProps } from '../StateFees/LateFee/LateFeeAmount';

export const LateFeeAmountWeekly2 = ({
  disabled,
  stateAbbr,
  freshStrategy
}: LateFeeCommonProps) => {
  const { control } = useFormContext();
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();

  const {
    presentInApi,
    fieldError,
    changed,
    fieldName,
    options,
    rules
  } = useRuleField(`${stateAbbr}.${BoundedAmountsLateFeeKeys.Weekly2}`);

  const currentValue = useWatch({ name: fieldName });
  const initialValue = get(initialConfigurations, fieldName);

  return (
    <TooltipItem
      title={fieldError?.message}
      arrow
      placement="bottom-start"
      TransitionProps={{ exit: false }}
    >
      <Controller
        as={AutoNumeric}
        control={control}
        name={fieldName}
        size="small"
        invalid={!!fieldError}
        icon={!!fieldError && <ErrorIcon />}
        disabled={disabled || (!presentInApi && !freshStrategy)}
        rules={(presentInApi || freshStrategy) && rules}
        options={options}
        changed={changed && (presentInApi || (!presentInApi && freshStrategy))}
        defaultValue={
          presentInApi && currentValue === undefined
            ? initialValue
            : currentValue || ''
        }
      />
    </TooltipItem>
  );
};
