import React from 'react';
import clsx from 'clsx';

import styles from './InputList.module.scss';

interface Props {
  title?: React.ReactNode;
  children: JSX.Element;
  as?: string;
  noDividerLine?: boolean;
  titleClassName?: string;
  className?: string;
}

export const inputListTestId = 'inputListTestId';

export const InputList = ({
  title,
  children,
  as,
  noDividerLine,
  titleClassName,
  className
}: Props) => (
  <div data-testid={inputListTestId} className={className}>
    {title && (
      <h4 className={clsx([styles.title, !!titleClassName && titleClassName])}>
        {title}
      </h4>
    )}
    {as === 'div' ? (
      <div
        className={clsx([
          styles.inputList,
          !noDividerLine && styles.inputListDivider
        ])}
      >
        {children}
      </div>
    ) : (
      <ul
        className={clsx([
          styles.inputList,
          !noDividerLine && styles.inputListDivider
        ])}
      >
        {children}
      </ul>
    )}
  </div>
);
