import React from 'react';
import MuiErrorIcon from '@material-ui/icons/Error';

import styles from './ErrorIcon.module.scss';

export const ErrorIcon = () => (
  <MuiErrorIcon
    classes={{
      root: styles.errorIcon
    }}
  />
);
