import { omit } from 'lodash';

import { SelectedStoresActionType } from './selected-stores-actions';
import { Store } from '../../../domain/Store/Store';
import { ConfigurationsAction } from '../configurations-reducer';

export enum SelectCriteria {
  ByEntity = 'ByEntity',
  ByState = 'ByState'
}

export interface StoresByCriteria {
  [criteriaValue: string]: Store[];
}

export interface SelectedStoresState {
  selectedCriteria: SelectCriteria;
  selectedStores: StoresByCriteria;
}

export const selectedStoresInitialState: SelectedStoresState = {
  selectedCriteria: SelectCriteria.ByEntity,
  selectedStores: {}
};

export const orderSelectedStores = (
  allStores: StoresByCriteria,
  selectedStores: StoresByCriteria
): StoresByCriteria =>
  Object.keys(allStores).reduce(
    (acc: StoresByCriteria, criteriaName: string) => {
      if (selectedStores[criteriaName]) {
        acc[criteriaName] = selectedStores[criteriaName];

        return acc;
      }

      return acc;
    },
    {}
  );

export const selectedStoresReducer = (
  state: SelectedStoresState,
  action: ConfigurationsAction
): SelectedStoresState => {
  if (action.type === SelectedStoresActionType.SET_INITIAL_STATE) {
    return {
      selectedCriteria: action.payload.selectedCriteria,
      selectedStores: {}
    };
  }

  if (action.type === SelectedStoresActionType.SELECT_STORES) {
    const {
      payload: { allStores, selectedCriteria, selectedStores }
    } = action;

    return {
      selectedCriteria,
      selectedStores: orderSelectedStores(allStores, {
        ...state.selectedStores,
        ...selectedStores
      })
    };
  }

  if (action.type === SelectedStoresActionType.DESELECT_STORES) {
    const {
      payload: { criteriaName }
    } = action;

    return {
      ...state,
      selectedStores: omit(state.selectedStores, criteriaName)
    };
  }

  return state;
};
