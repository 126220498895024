import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { ConfigurationsRoute } from '../../config/route-config';
import { ConfigurationsProvider } from '../../context/configurations/ConfigurationsProvider';
import { Steps } from '../layout/steps/Steps';
import { SelectStores } from '../select-stores/SelectStores';
import { EditConfigs } from '../edit-configs/EditConfigs';
import { SaveConfigs } from '../save-configs/SaveConfigs';
import { StoresProvider } from '../../context/stores/StoresProvider';
import { StoresFetcher } from './StoresFetcher';
import { ToastProvider } from '../common/toast/ToastProvider';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <div data-testid={routesTestId}>
    <StoresProvider>
      <StoresFetcher />
      <ConfigurationsProvider>
        <ToastProvider>
          <Steps />
          <Switch>
            <Route exact path={ConfigurationsRoute.Index}>
              <Redirect to={ConfigurationsRoute.Select} />
            </Route>
            <Route path={ConfigurationsRoute.Select} component={SelectStores} />
            <Route
              exact
              path={ConfigurationsRoute.Edit}
              component={EditConfigs}
            />
            <Route
              exact
              path={ConfigurationsRoute.Save}
              component={SaveConfigs}
            />
            <Route>
              <Redirect to={ConfigurationsRoute.Index} />
            </Route>
          </Switch>
        </ToastProvider>
      </ConfigurationsProvider>
    </StoresProvider>
  </div>
);
