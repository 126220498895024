import React, { useMemo, useState } from 'react';
import { Checkbox, Button } from '@rentacenter/racstrap';

import { useConfigurationsStateContext } from '../../context/configurations/configurations-contexts';
import { Modal, ModalContent, ModalButtons } from '../common/modal/Modal';
import { EditConfigurationsForm } from '../edit-configs/form/EditConfigurationsForm';
import styles from './SaveConfigs.module.scss';

export const SaveConfigurationsFormContainer = () => {
  const {
    initialConfigurations: { configurations: initialConfigurations },
    changedConfigurations
  } = useConfigurationsStateContext();

  const [confirmOpened, setConfirmOpened] = useState(true);

  const handleChange = () => {
    sessionStorage.setItem('hasUserCheckedDoNotShow', 'true');
  };

  const hasUserCheckedDoNotShow =
    sessionStorage.getItem('hasUserCheckedDoNotShow') === 'true';

  return useMemo(
    () => (
      <>
        {initialConfigurations && changedConfigurations && (
          <>
            <Modal
              isOpen={confirmOpened && !hasUserCheckedDoNotShow}
              onClose={() => setConfirmOpened(false)}
              title="Preview Configurations Information"
            >
              <ModalContent>
                <ul className={styles.modalContent}>
                  <li className={styles.modalContentItem}>
                    In Preview Mode, you cannot interact with the fields
                  </li>
                  <li className={styles.modalContentItem}>
                    Changes to configurations will be displayed in a green color
                  </li>
                  <li className={styles.modalContentItem}>
                    The fields that have not been changed are displayed as
                    disabled
                  </li>
                  <li className={styles.modalContentItem}>
                    If errors to any fields occur, you will not be able to save
                    and submit before resolving the error
                  </li>
                </ul>
              </ModalContent>
              <ModalButtons>
                <Checkbox
                  classes={{ root: styles.doNotShow }}
                  labelText="Do not show this again"
                  onChange={handleChange}
                />
                <Button color="primary" onClick={() => setConfirmOpened(false)}>
                  Ok
                </Button>
              </ModalButtons>
            </Modal>
            <EditConfigurationsForm
              isCollapseHidden={false}
              disabled={true}
              defaultConfigurations={changedConfigurations}
              initialConfigurations={initialConfigurations}
            />
          </>
        )}
      </>
    ),
    [
      initialConfigurations,
      changedConfigurations,
      confirmOpened,
      hasUserCheckedDoNotShow
    ]
  );
};
