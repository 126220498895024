import React from 'react';
import { Label, Radio, RadioProps } from '@rentacenter/racstrap';
import clsx from 'clsx';
import styles from './RadioGroup.module.scss';
import { InputHelp } from '../input-help/InputHelp';

export interface RadioGroupProps {
  changed?: boolean;
  tooltip?: React.ReactNode;
}

type Props = RadioGroupProps & RadioProps;

export const RadioGroup = (props: Props) => {
  const { changed, label, tooltip, ...rest } = props;

  return (
    <>
      {label && (
        <Label>
          {label} {tooltip && <InputHelp text={tooltip} />}
        </Label>
      )}
      <Radio
        classes={{
          radio: clsx(changed && styles.radioChanged),
          checked: clsx(changed && styles.checked)
        }}
        {...rest}
      />
    </>
  );
};
