import { Action } from '../../Action';
import { Configurations } from '../../../domain/Configuration/Configurations';

export enum InitialConfigurationsActionType {
  GET_INITIAL_CONFIGURATIONS = 'GET_INITIAL_CONFIGURATIONS',
  GET_INITIAL_CONFIGURATIONS_SUCCESS = 'GET_INITIAL_CONFIGURATIONS_SUCCESS',
  GET_INITIAL_CONFIGURATIONS_ERROR = 'GET_INITIAL_CONFIGURATIONS_ERROR'
}

export interface GetInitialConfigurationsAction extends Action {
  type: InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS;
}

export const getInitialConfigurationsAction = (): GetInitialConfigurationsAction => ({
  type: InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS
});

export interface GetInitialConfigurationsSuccessAction extends Action {
  type: InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS_SUCCESS;
  payload: { configurations?: Configurations };
}

export const getInitialConfigurationsSuccessAction = (
  configurations: Configurations
): GetInitialConfigurationsSuccessAction => ({
  type: InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS_SUCCESS,
  payload: { configurations }
});

export interface GetInitialConfigurationsErrorAction extends Action {
  type: InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS_ERROR;
}

export const getInitialConfigurationsErrorAction = (): GetInitialConfigurationsErrorAction => ({
  type: InitialConfigurationsActionType.GET_INITIAL_CONFIGURATIONS_ERROR
});

export type InitialConfigurationsAction =
  | GetInitialConfigurationsAction
  | GetInitialConfigurationsSuccessAction
  | GetInitialConfigurationsErrorAction;
