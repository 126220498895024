import React from 'react';
import { Label, NumericInput, NumericInputProps } from '@rentacenter/racstrap';
import clsx from 'clsx';

import styles from './AutoNumeric.module.scss';

import { InputHelp } from '../input-help/InputHelp';

interface AutoNumericProps {
  changed?: boolean;
  tooltip?: React.ReactNode;
}

type Props = AutoNumericProps & NumericInputProps;

export const AutoNumeric = ({
  changed,
  invalid,
  errorMessage,
  classes,
  label,
  tooltip,
  ...rest
}: Props) => {
  return (
    <>
      {label && (
        <Label>
          {label} {tooltip && <InputHelp text={tooltip} />}
        </Label>
      )}
      <NumericInput
        classes={{
          ...classes,
          input: clsx([
            !invalid && !errorMessage && changed && styles.changed,
            classes?.input
          ]),
          disabled: clsx(styles.disabled, classes?.disabled)
        }}
        errorMessage={errorMessage}
        invalid={invalid}
        {...rest}
      />
    </>
  );
};
