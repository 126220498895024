import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { get } from 'lodash';

import styles from './InventoryDepeciation.module.scss';

import {
  useConfigurationsSelectorsContext,
  useConfigurationsValidationContext,
  useConfigurationsStateContext
} from '../../../../context/configurations/configurations-contexts';
import { ComponentProps } from '../EditConfigurationsForm';
import { CollapsableCard } from '@rentacenter/racstrap';
import { InventoryDepreciationKeys } from '../../../../domain/Configuration/ConfigurationKeys';
import { FieldChangedContext } from '../FieldChangedContext';
import { RadioGroup } from '../../../common/form/radio-group/RadioGroup';
import { AutoNumeric } from '../../../common/form/auto-numeric/AutoNumeric';
import { InputField } from '../../../common/form/input-field/InputField';
import { InputHelp } from '../../../common/form/input-help/InputHelp';

export const collapsibleCardTestId = 'collapsibleCardTestId';

export const InventoryDepreciation = (props: ComponentProps) => {
  const { disabled, configurations } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { control, errors } = useFormContext();
  const { fieldChanged } = useContext(FieldChangedContext);
  const { countUniqRules } = useConfigurationsSelectorsContext();
  const validation = useConfigurationsValidationContext();
  const initialWaitPeriodDeferredRuleName = `COUNTRY.${InventoryDepreciationKeys.InitialWaitPeriodDeferred}`;
  const initialWaitPeriodDeferredFieldName = `${initialWaitPeriodDeferredRuleName}.value`;
  const appliedDepriciationMethodFieldName = `COUNTRY.${InventoryDepreciationKeys.AppliedDepriciationMethod}`;
  const { options, ...rules } = get(
    validation,
    initialWaitPeriodDeferredFieldName
  );

  const itemNr = countUniqRules(Object.values(InventoryDepreciationKeys));

  const presentInApi = !!get(
    initialConfigurations,
    initialWaitPeriodDeferredRuleName
  );

  const consecutiveWaitDaysForSwitchChanged = fieldChanged(
    `COUNTRY.${InventoryDepreciationKeys.ConsecutiveWaitDaysForSwitch}.value`
  );
  const initialWaitPeriodChanged = fieldChanged(
    `COUNTRY.${InventoryDepreciationKeys.InitialWaitPeriod}.value`
  );
  const remainingLifeInMonthsChanged = fieldChanged(
    `COUNTRY.${InventoryDepreciationKeys.RemainingLifeInMonths}.value`
  );
  const initialWaitPeriodDeferredChanged = fieldChanged(
    initialWaitPeriodDeferredFieldName
  );

  const initialWaitPeriodDeferredFieldError = get(
    errors,
    initialWaitPeriodDeferredFieldName
  );

  const consecutiveWaitDaysOptions =
    configurations?.COUNTRY[
      InventoryDepreciationKeys.ConsecutiveWaitDaysForSwitch
    ]?.options?.map(value => ({ label: value, value })) || [];

  const initialWaitPeriodOptions =
    configurations?.COUNTRY[
      InventoryDepreciationKeys.InitialWaitPeriod
    ]?.options?.map(value => ({ label: value, value })) || [];

  const remainingLifeInMonthsOptions =
    configurations?.COUNTRY[
      InventoryDepreciationKeys.RemainingLifeInMonths
    ]?.options?.map(value => ({ label: value, value })) || [];

  return (
    <CollapsableCard
      className={styles.card}
      title={
        ((
          <>
            Inventory depreciation ({itemNr}){' '}
            <InputHelp
              text={
                <>
                  Depreciation Types:
                  <ul>
                    <li>
                      Straight Line – Depreciation when an inventory is
                      Stock-Idle
                    </li>
                    <li>
                      Income Forecasting – Depreciation for inventory On-Rent
                    </li>
                    <li>
                      Deferred Straight Line – Depreciation similar to Straight
                      Line but applies to when inventory is stock-idle or
                      on-rent
                    </li>
                  </ul>
                </>
              }
            />
          </>
        ) as unknown) as string // // UPGRADE-TODO: make CollapsableCard accept React.ReactNode for title
      }
      expanded={disabled}
      data-testid={collapsibleCardTestId}
    >
      <Grid container>
        <Grid item lg={12}>
          <Box mb="1.5rem">
            <Controller
              as={RadioGroup}
              label="Consecutive Idle days before straightline depreciation starts for
                items on Aged Idle method"
              name={`COUNTRY.${InventoryDepreciationKeys.ConsecutiveWaitDaysForSwitch}.value`}
              options={consecutiveWaitDaysOptions}
              control={control}
              disabled={disabled}
              changed={consecutiveWaitDaysForSwitchChanged}
              tooltip="How long an item has to be in idle status to qualify for Straight Line depreciation."
            />
          </Box>
          <Box mb="1.5rem">
            <Controller
              as={RadioGroup}
              label="Wait period for depreciation for items on Aged Idle method"
              name={`COUNTRY.${InventoryDepreciationKeys.InitialWaitPeriod}.value`}
              options={initialWaitPeriodOptions}
              control={control}
              disabled={disabled}
              changed={initialWaitPeriodChanged}
              tooltip="Add an additional criteria to ensure that the item is
              of a certain age (in addition of consecutive days idle) before
              qualifying for Straight Line depreciation."
            />
          </Box>
          <Box mb="1.5rem">
            <Controller
              as={RadioGroup}
              label="Period for straight line depreciation for items on Aged Idle
              method"
              name={`COUNTRY.${InventoryDepreciationKeys.RemainingLifeInMonths}.value`}
              options={remainingLifeInMonthsOptions}
              control={control}
              disabled={disabled}
              changed={remainingLifeInMonthsChanged}
              tooltip="The total depreciation life of an inventory."
            />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box mb="1.5rem">
            <Controller
              as={AutoNumeric}
              options={options}
              rules={presentInApi && rules}
              control={control}
              errorMessage={initialWaitPeriodDeferredFieldError?.message}
              label="Deferred Straightline Depreciation"
              name={initialWaitPeriodDeferredFieldName}
              placeholder="Deferred Straightline Depreciation"
              invalid={!!initialWaitPeriodDeferredFieldError}
              disabled={disabled || !presentInApi}
              changed={initialWaitPeriodDeferredChanged}
              tooltip="Wait period before an item qualifies for Deferred Straight line depreciation."
            />
          </Box>
          <Box mb="1rem">
            <Controller
              as={InputField}
              control={control}
              readOnly
              disabled
              label="Applied Depreciation Method"
              name={appliedDepriciationMethodFieldName}
              tooltip="Aged Idle Straight Line – Combination of Straight
              line when an inventory is Stock-Idle and Income Forecasting
              when taking a payment."
            />
          </Box>
        </Grid>
      </Grid>
    </CollapsableCard>
  );
};
