import { createContext } from 'react';
import { get } from 'lodash';

import { Configurations } from '../../../domain/Configuration/Configurations';

interface FieldChangedInterface {
  fieldChanged: (path: string) => boolean;
}

export const FieldChangedContext = createContext({} as FieldChangedInterface);

export const fieldChanged = (
  initial: Configurations,
  changed: Configurations | undefined
) => (path: string): boolean => {
  if (!changed) {
    return false;
  }

  if (get(initial, path) === get(changed, path)) {
    return false;
  }

  return true;
};
