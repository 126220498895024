import React, { MouseEvent, RefObject } from 'react';
import { ListItem as MuiListItem, ListItemIcon } from '@material-ui/core';

import styles from './ListItem.module.scss';

import { ErrorIcon } from '../form/error-icon/ErrorIcon';

export const tabTestId = 'tabTestId';

interface Props {
  readonly className?: string;
  readonly children?: string;
  readonly isError?: boolean;
  readonly selected?: boolean;
  readonly innerRef?: RefObject<unknown>;
  readonly handleListItemClick?: (event: MouseEvent<{}>) => void;
}

export const ListItem = (props: Props) => {
  const {
    className,
    handleListItemClick,
    children,
    isError,
    selected,
    innerRef,
    ...rest
  } = props;

  return (
    <MuiListItem
      classes={{
        root: styles.root,
        selected: styles.selected
      }}
      disableGutters
      innerRef={innerRef}
      selected={selected}
      onClick={handleListItemClick}
      className={className}
      {...rest}
    >
      {isError && (
        <ListItemIcon className={styles.iconWidth}>
          <ErrorIcon />
        </ListItemIcon>
      )}
      {children}
    </MuiListItem>
  );
};
