import { useContext } from 'react';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import {
  useConfigurationsStateContext,
  useConfigurationsValidationContext
} from '../../../context/configurations/configurations-contexts';
import { FieldChangedContext } from './FieldChangedContext';

export function useRuleField(ruleName: string) {
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { errors } = useFormContext();
  const validation = useConfigurationsValidationContext();
  const { fieldChanged } = useContext(FieldChangedContext);

  const fieldName = `${ruleName}.value`;

  const { options, ...rules } = get(validation, fieldName);

  return {
    presentInApi: !!get(initialConfigurations, ruleName),
    fieldError: get(errors, fieldName),
    changed: fieldChanged(fieldName),
    fieldName,
    options,
    rules
  };
}
