import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './InputHelp.module.scss';

import { TooltipItem } from '../../TooltipItem';

export interface InputHelpProps {
  text: React.ReactNode;
}

export const InputHelp = ({ text }: InputHelpProps) => {
  return (
    <TooltipItem title={text} as="span" classes={{ tooltip: styles.tooltip }}>
      <FontAwesomeIcon icon={faInfoCircle} />
    </TooltipItem>
  );
};
