import { Action } from '../../Action';
import { SelectCriteria, StoresByCriteria } from './selected-stores-reducer';

export enum SelectedStoresActionType {
  SET_INITIAL_STATE = 'SET_INITIAL_STATE',
  SELECT_STORES = 'SELECT_STORES',
  DESELECT_STORES = 'DESELECT_STORES'
}

export interface SelectStoresPayload {
  allStores: StoresByCriteria;
  selectedCriteria: SelectCriteria;
  selectedStores: StoresByCriteria;
}

export interface SetInitialStatesAction extends Action {
  type: SelectedStoresActionType.SET_INITIAL_STATE;
  payload: { selectedCriteria: SelectCriteria };
}

export const setInitialStateAction = (
  selectedCriteria: SelectCriteria
): SetInitialStatesAction => ({
  type: SelectedStoresActionType.SET_INITIAL_STATE,
  payload: { selectedCriteria }
});

export interface SelectStoresAction extends Action {
  type: SelectedStoresActionType.SELECT_STORES;
  payload: SelectStoresPayload;
}

export const selectStoresAction = (
  payload: SelectStoresPayload
): SelectStoresAction => ({
  type: SelectedStoresActionType.SELECT_STORES,
  payload
});

export interface DeselectStoresPayload {
  criteriaName: string;
}

export interface DeselectStoresAction extends Action {
  type: SelectedStoresActionType.DESELECT_STORES;
  payload: DeselectStoresPayload;
}

export const deselectStoresAction = (
  criteriaName: string
): DeselectStoresAction => ({
  type: SelectedStoresActionType.DESELECT_STORES,
  payload: { criteriaName }
});

export type SelectedStoreAction =
  | SetInitialStatesAction
  | SelectStoresAction
  | DeselectStoresAction;
