import React, { PropsWithChildren } from 'react';
import styles from './Footer.module.scss';

export const footerTestId = 'footerTestId';

export const Footer = ({ children }: PropsWithChildren<{}>) => (
  <footer className={styles.footer} data-testid={footerTestId}>
    <div className={styles.btnWrapper}>{children}</div>
  </footer>
);
