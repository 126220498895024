import React from 'react';

import styles from '../../../EditConfigurationsForm.module.scss';

import { BoundedLesserOfLateFeeKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsStateContext } from '../../../../../../context/configurations/configurations-contexts';
import { countUniqRules } from '../../../../../../context/configurations/configurations-selectors';
import { LateFeePercentage } from './LateFeePercentage';
import { LowerLateFeeBound } from './LowerLateFeeBound';
import { UpperLateFeeBound } from './UpperLateFeeBound';
import { StateFeesProps } from '../StateFees/StateFees';

export const BoundedLesserOfLateFee = (props: StateFeesProps) => {
  const { currentStateAbbr, disabled } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();

  const initialConfig = {
    [currentStateAbbr]: (initialConfigurations || {})[currentStateAbbr],
    COUNTRY: {}
  };

  const freshStrategy =
    countUniqRules(initialConfig)(Object.values(BoundedLesserOfLateFeeKeys)) ===
    0;

  return (
    <div className={styles.tableResponsive}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th scope="col">
              <span className={styles.label}>Late Fee Percentage</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Lower Late Fee Bounded</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Upper Late Fee Bounded</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <LateFeePercentage
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
            <td>
              <LowerLateFeeBound
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
            <td>
              <UpperLateFeeBound
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
