import React, { useEffect, useState } from 'react';
import { isArray, mergeWith } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './EditConfigurationsForm.module.scss';

import { Configurations } from '../../../domain/Configuration/Configurations';
import { General } from './components/general/General';
import { InventoryDepreciation } from './components/InventoryDepreciation';
import { Fees } from './components/fees/Fees';
import { Payment } from './components/payment/Payment';
import { configurationsFormId } from '../../../config/edit-configs-form-settings';
import { useUserConfigurationsThunksContext } from '../../../context/configurations/configurations-contexts';

import { FieldChangedContext, fieldChanged } from './FieldChangedContext';

import { ErrorModal } from './components/errorModal/ErrorModal';

interface Props {
  readonly isCollapseHidden?: boolean;
  readonly disabled?: boolean;
  readonly defaultConfigurations: Configurations;
  readonly initialConfigurations?: Configurations;
  readonly onChange?: (
    configurations: Configurations,
    isTouched: boolean
  ) => void;
}

export interface BasicComponentProps {
  readonly isCollapseHidden?: boolean;
  readonly disabled?: boolean;
}

export interface ComponentProps extends BasicComponentProps {
  readonly configurations: Configurations;
}

export const editConfigurationsFormTestId = 'editConfigurationsFormTestId';
export const configSubmitBtnId = 'configSubmitBtnId';

export const EditConfigurationsForm = (props: Props) => {
  const [errors, setErrors] = useState<any>();

  const {
    isCollapseHidden,
    disabled,
    defaultConfigurations,
    initialConfigurations,
    onChange
  } = props;

  const {
    submitConfigurations: submitConfigurationsThunk
  } = useUserConfigurationsThunksContext();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultConfigurations
  });

  const { getValues, watch, trigger, handleSubmit } = methods;

  const onSubmit = handleSubmit(
    data => {
      // data has only values
      // and initialConfigurations the full auxiliary fields to be submitted to the API
      submitConfigurationsThunk(
        mergeWith({}, initialConfigurations, data, (dest: any, src: any) => {
          if (isArray(dest)) {
            return src;
          }
        }),
        initialConfigurations
      );
    },
    errors => {
      setErrors(errors);
    }
  );

  const changes = watch();

  useEffect(() => {
    if (changes && onChange) {
      onChange(changes, true);
    }
  }, [onChange, changes]);

  useEffect(() => {
    if (defaultConfigurations && onChange) {
      onChange(defaultConfigurations, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // componentDidMount only

  // seems to be useful on the 3rd screen :)
  useEffect(() => {
    // TODO fix typings
    trigger();
  }, [getValues, trigger, disabled]);

  return (
    <>
      <ErrorModal errors={errors} />
      <FieldChangedContext.Provider
        value={{
          fieldChanged: fieldChanged(
            defaultConfigurations,
            initialConfigurations
          )
        }}
      >
        <FormProvider {...methods}>
          <form
            id={configurationsFormId}
            data-testid={editConfigurationsFormTestId}
            className={styles.panelsWrapper}
            onSubmit={onSubmit}
            noValidate
          >
            <General
              isCollapseHidden={isCollapseHidden}
              configurations={defaultConfigurations}
              disabled={disabled}
            />
            <InventoryDepreciation
              configurations={defaultConfigurations}
              disabled={disabled}
            />
            <Fees
              isCollapseHidden={isCollapseHidden}
              configurations={defaultConfigurations}
              disabled={disabled}
            />
            <Payment
              isCollapseHidden={isCollapseHidden}
              configurations={defaultConfigurations}
              disabled={disabled}
            />

            <button
              id={configSubmitBtnId}
              form={configurationsFormId}
              type="submit"
              style={{ display: 'none' }}
            >
              This is needed for IE11
            </button>
          </form>
        </FormProvider>
      </FieldChangedContext.Provider>
    </>
  );
};
