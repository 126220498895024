import clsx from 'clsx';
import React, { useEffect } from 'react';
import {
  useToastActionsContext,
  ToastType,
  useToastState
} from './ToastProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faExclamationCircle,
  faInfoCircle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import styles from './ToastDisplay.module.scss';

export const toastTestId = 'toastTestId';

export const ToastDisplay = () => {
  const state = useToastState();
  const { hideToast } = useToastActionsContext();

  const getToastTypeClass = (type: ToastType | undefined) => {
    switch (type) {
      case ToastType.Info:
        return styles.info;
      case ToastType.Success:
        return styles.success;
      case ToastType.Warning:
        return styles.warning;
      case ToastType.Error:
        return styles.error;
      default:
        return styles.info;
    }
  };

  const getIconType = (type: ToastType | undefined) => {
    switch (type) {
      case ToastType.Info:
        return faInfoCircle;
      case ToastType.Success:
        return faCheckCircle;
      case ToastType.Warning:
      case ToastType.Error:
      default:
        return faExclamationCircle;
    }
  };

  const toastTypeClass = getToastTypeClass(state.type);
  const iconType = getIconType(state.type);

  useEffect(() => {
    if (!state.duration) {
      return;
    }
    const timer = setTimeout(hideToast, state.duration);

    return () => {
      clearTimeout(timer);
    };
  }, [hideToast, state.duration]);

  return (
    <div className={styles.toast} data-testid={toastTestId}>
      <div className={clsx(styles.side, toastTypeClass)}></div>
      <div className={clsx(styles.icon, toastTypeClass)}>
        <FontAwesomeIcon icon={iconType} />
      </div>
      <div className={styles.message}>{state.message}</div>
      <div className={styles.action}>
        <FontAwesomeIcon icon={faTimes} onClick={() => hideToast()} />
      </div>
    </div>
  );
};
