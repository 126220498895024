import React, { useContext } from 'react';
import { get } from 'lodash';

import styles from './RecycleFee.module.scss';

import { Switch } from '../../../../../common/form/switch/Switch';
import { GeneralKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { FieldChangedContext } from '../../../FieldChangedContext';
import { useConfigurationsStateContext } from '../../../../../../context/configurations/configurations-contexts';
import { StateFeesProps } from './StateFees';

export const RecycleFee = (props: StateFeesProps) => {
  const { currentStateAbbr, configurations, disabled } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { fieldChanged } = useContext(FieldChangedContext);

  const fieldName = `${currentStateAbbr}.${GeneralKeys.RecycleFee}`;
  const fieldValue = `${currentStateAbbr}.${GeneralKeys.RecycleFee}.value`;
  const presentInApi = !!get(initialConfigurations, fieldName);

  return (
    <Switch
      label="Recycle Fee"
      classes={{ root: styles.switch }}
      name={fieldValue}
      defaultChecked={get(configurations, fieldValue)}
      disabled={disabled || !presentInApi}
      changed={fieldChanged(fieldValue)}
      isSeparatorVisible={false}
      isValueVisible
    />
  );
};
