import React from 'react';
import { CollapsableCard } from '@rentacenter/racstrap';

import typographyStyles from '../typography.module.scss';
import styles from './Payment.module.scss';

import { ConfigurationGroupTitle } from '../../../../../config/edit-configs-form-settings';
import { PaymentKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsSelectorsContext } from '../../../../../context/configurations/configurations-contexts';
import { ComponentProps } from '../../EditConfigurationsForm';
import { StatesTabs } from '../../StatesTabs/StatesTabs';
import { ReferralRentalPaymentCount } from './ReferralRentalPaymentCount';
import { SameAsCashDaysPrinted } from './SameAsCashDaysPrinted';
import { SameAsCashDays } from './SameAsCashDays';
import { AllowFinalPaymentOnline } from './AllowFinalPaymentOnline';
import { AllowFinalPaymentAutopay } from './AllowFinalPaymentAutopay';
import { PrintEPOHistory } from './PrintEPOHistory';
import { TotalCostLimit } from './TotalCostLimit';
import { SameAsCashDaysInputs } from './SameAsCashDaysInputs/SameAsCashDaysInputs';
import { EPO } from './EPO/EPO';

export const collapsibleCardTestId = 'collapsibleCardTestId';

export const Payment = (props: ComponentProps) => {
  const { disabled, configurations } = props;
  const { countUniqRules } = useConfigurationsSelectorsContext();

  const itemNr = countUniqRules(Object.values(PaymentKeys));

  return (
    <CollapsableCard
      className={styles.card}
      title={`${ConfigurationGroupTitle.payment} (${itemNr})`}
      expanded={disabled}
      data-testid={collapsibleCardTestId}
    >
      <div className={styles.sacSection}>
        <SameAsCashDaysInputs
          disabled={disabled}
          configurations={configurations}
          sacKey={PaymentKeys.SameAsCashDaysInputs}
        />
        <SameAsCashDaysInputs
          disabled={disabled}
          configurations={configurations}
          sacKey={PaymentKeys.SameAsCashDaysPrintedInputs}
        />
      </div>
      <div className={styles.referralPaymentSection}>
        <ReferralRentalPaymentCount />
      </div>
      <div className={styles.switchesSection}>
        <div className={styles.switchItem}>
          <SameAsCashDaysPrinted
            disabled={disabled}
            configurations={configurations}
          />
        </div>
        <div className={styles.switchItem}>
          <SameAsCashDays disabled={disabled} configurations={configurations} />
        </div>
        <div className={styles.switchItem}>
          <AllowFinalPaymentOnline configurations={configurations} />
        </div>
        <div className={styles.switchItem}>
          <AllowFinalPaymentAutopay configurations={configurations} />
        </div>
        <div className={styles.switchItem}>
          <PrintEPOHistory configurations={configurations} />
        </div>
      </div>

      <StatesTabs
        className={styles.stateSelectorContainer}
        data-testid="paymentStateSelector"
        rulesKeys={[
          PaymentKeys.EPOPercentRemainingRent,
          PaymentKeys.EPOPercentCashPriceRentPaid,
          PaymentKeys.EPOCachePriceTotalCostRatio,
          PaymentKeys.EPOCachePriceAndTerms,
          PaymentKeys.EPORentPaidAcquisitionCost,
          PaymentKeys.TotalCostLimit
        ]}
        scrollOnErrors={!disabled}
        renderState={abbr => (
          <>
            <h1 className={typographyStyles.sectionTitle}>EPO Strategy</h1>
            <EPO
              currentStateAbbr={abbr}
              configurations={configurations}
              disabled={disabled}
            />

            <hr className={typographyStyles.hr} />
            <h1 className={typographyStyles.sectionTitle}>Total Cost Limit</h1>
            <TotalCostLimit currentStateAbbr={abbr} />
          </>
        )}
      />
    </CollapsableCard>
  );
};
