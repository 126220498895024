import React from 'react';

import styles from '../../../EditConfigurationsForm.module.scss';

import { FixedAmountLateFeeKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsStateContext } from '../../../../../../context/configurations/configurations-contexts';
import { countUniqRules } from '../../../../../../context/configurations/configurations-selectors';
import { LateFeeAmountMonthly } from './LateFeeAmountMonthly';
import { MultipleLateFeesAllowed } from './MultipleLateFeesAllowed';
import { LateFeeAmountWeekly } from './LateFeeAmountWeekly';
import { StateFeesProps } from '../StateFees/StateFees';

export const FixedAmountLateFee = (props: StateFeesProps) => {
  const { currentStateAbbr, configurations, disabled } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();

  const initialConfig = {
    [currentStateAbbr]: (initialConfigurations || {})[currentStateAbbr],
    COUNTRY: {}
  };

  const freshStrategy =
    countUniqRules(initialConfig)(Object.values(FixedAmountLateFeeKeys)) === 0;

  return (
    <div className={styles.tableResponsive}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th scope="col">
              <span className={styles.label}>Late Fee Amount Monthly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Late Fee Amount Weekly</span>
            </th>
            <th scope="col">
              <span className={styles.label}>Multiple Late Fees Allowed</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <LateFeeAmountMonthly
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
            <td>
              <LateFeeAmountWeekly
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                freshStrategy={freshStrategy}
              />
            </td>
            <td>
              <MultipleLateFeesAllowed
                disabled={disabled}
                stateAbbr={currentStateAbbr}
                configurations={configurations}
                freshStrategy={freshStrategy}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
