import React, { useCallback, useContext, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rentacenter/racstrap';
import { SelectedStoresSelectorsContext } from '../../context/configurations/selected-stores/selected-stores-context';
import { ConfigurationsRoute } from '../../config/route-config';

export const selectStoresNextButtonTestId = 'selectStoresNextButtonTestId';

export const SelectStoresNextButton = withRouter(
  ({ history }: RouteComponentProps) => {
    const selectedStoresSelectors = useContext(SelectedStoresSelectorsContext);

    const isDisabled = !selectedStoresSelectors.arePresent();

    const handleClick = useCallback(() => {
      history.push(ConfigurationsRoute.Edit);
    }, [history]);

    return useMemo(
      () => (
        <Button
          color="primary"
          disabled={isDisabled}
          onClick={handleClick}
          data-testid={selectStoresNextButtonTestId}
        >
          Next
        </Button>
      ),
      [isDisabled, handleClick]
    );
  }
);
