import React, { useMemo } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

import styles from './SelectionPreview.module.scss';

import { ScrollableContent } from '../../common/scrollable-content/ScrollableContent';
import { SelectionPreviewEmpty } from '../selection-preview-empty/SelectionPreviewEmpty';
import { useConfigurationsStateContext } from '../../../context/configurations/configurations-contexts';
import { Store } from '../../../domain/Store/Store';
import { SelectionPreviewItem } from './item/SelectionPreviewItem';
import { getUniqueNumberOfStates } from './getUniqueNumberOfStates';
import { SubtractFromHeight } from '../../common/scrollable-content/ScrollableContent';
import { SelectCriteria } from '../../../context/configurations/selected-stores/selected-stores-reducer';
import { GradientMask } from '../../common/gradient-mask/GradientMask';

interface SelectionPreviewProps {
  readonly subtractFromHeight: SubtractFromHeight;
}

interface CountProps {
  readonly stores: Store[];
  readonly selectedCriteria: SelectCriteria;
}

const Count = ({ stores, selectedCriteria }: CountProps) =>
  selectedCriteria === SelectCriteria.ByEntity ? (
    <span className={styles.storesInStateCount}>
      {stores.length} stores in {getUniqueNumberOfStates(stores)} states
    </span>
  ) : (
    <span>| {stores.length} stores</span>
  );

export const selectionPreviewTestId = 'selectionPreviewTestId';

export const SelectionPreview = ({
  subtractFromHeight
}: SelectionPreviewProps) => {
  const {
    selectedStores: selectedStoresState
  } = useConfigurationsStateContext();

  return useMemo(() => {
    const { selectedStores, selectedCriteria } = selectedStoresState;

    return (
      <Box
        height="100%"
        className={clsx([styles.selectionPreview])}
        data-testid={selectionPreviewTestId}
      >
        <h2 className={styles.selectionPreviewHeader}>Selection preview</h2>
        {Object.keys(selectedStores).length ? (
          <div style={{ position: 'relative' }}>
            <ScrollableContent subtractFromHeight={subtractFromHeight}>
              <div className={styles.selectionPreviewContent}>
                {Object.keys(selectedStores).map((criteriaName: string) => (
                  <div
                    className={styles.selectionPreviewBox}
                    key={criteriaName}
                  >
                    <p className={styles.selectionPreviewSummary}>
                      <span className={styles.selectionPreviewCriteria}>
                        {criteriaName}
                      </span>
                      <span className={styles.selectionPreviewSummaryCount}>
                        <Count
                          stores={selectedStores[criteriaName]}
                          selectedCriteria={selectedCriteria}
                        />
                      </span>
                    </p>
                    <ul className={styles.selectionPreviewList}>
                      {selectedStores[criteriaName].map((store: Store) => (
                        <SelectionPreviewItem
                          store={store}
                          key={store.storeNum}
                          selectedCriteria={selectedCriteria}
                        />
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </ScrollableContent>
            <GradientMask />
          </div>
        ) : (
          <SelectionPreviewEmpty />
        )}
      </Box>
    );
  }, [selectedStoresState, subtractFromHeight]);
};
