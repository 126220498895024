import { chain, flatten } from 'lodash';

import { State, StateAbbreviation } from '../../../domain/Store/State';
import { Store } from '../../../domain/Store/Store';
import { SelectedStoresState } from './selected-stores-reducer';
import { getStoresByState } from '../../../context/stores/stores-selectors';

export interface SelectedStoresSelectors {
  arePresent(): boolean;
  firstStoresNums(): string[];
  firstStore(): Store | undefined;
  uniqueStates(): State[];
}

export type StoresByState = {
  [key in StateAbbreviation | 'COUNTRY']?: string[];
};

export const arePresent = (state: SelectedStoresState) => () => {
  const { selectedStores } = state;

  return Object.keys(selectedStores).length > 0;
};

export const firstStoresNums = ({
  selectedStores
}: SelectedStoresState) => (): string[] => {
  const flattenSelectedStore = flatten(Object.values(selectedStores));
  return Object.values(getStoresByState(flattenSelectedStore)())
    .reduce((acc, stores: Store[]) => acc.concat([stores[0]]), [])
    .map(({ storeNum }: Store) => storeNum);
};

export const firstStore = ({ selectedStores }: SelectedStoresState) => ():
  | Store
  | undefined =>
  chain(selectedStores)
    .values()
    .first()
    .first()
    .value();

export const uniqueStates = ({
  selectedStores
}: SelectedStoresState) => (): State[] =>
  chain(selectedStores)
    .values()
    .flatten()
    .map((store: Store) => store.state)
    .uniqBy((state: State) => state.name)
    .value();

export const selectedStoresNums = ({
  selectedStores
}: SelectedStoresState) => (): string[] => {
  return flatten(Object.values(selectedStores)).map(
    ({ storeNum }: Store) => storeNum
  );
};

export const selectedStoresByState = ({
  selectedStores
}: SelectedStoresState) => {
  return chain(selectedStores)
    .values()
    .flatten()
    .reduce((result, store) => {
      result['COUNTRY'] = result['COUNTRY'] || [];
      result[store.state.abbreviation] = result[store.state.abbreviation] || [];
      result[store.state.abbreviation]?.push(store.storeNum);
      result['COUNTRY'].push(store.storeNum);
      return result;
    }, {} as StoresByState)
    .value();
};
