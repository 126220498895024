import React, { PropsWithChildren } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import styles from './ScrollableContent.module.scss';

export interface SubtractFromHeight {
  xs: string;
  lg: string;
}

interface Props {
  subtractFromHeight: SubtractFromHeight;
}

export const subtractFromHeight: SubtractFromHeight = { xs: '127', lg: '307' };

const makeHeightStyle = (toSubtract: SubtractFromHeight) =>
  makeStyles((theme: Theme) =>
    createStyles({
      height: {
        [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
          height: `calc(100vh - ${toSubtract.xs}px)`
        },
        [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
          height: `calc(100vh - ${toSubtract.lg}px)`
        }
      }
    })
  );

export const ScrollableContent = (props: PropsWithChildren<Props>) => {
  const heightStyle = makeHeightStyle(props.subtractFromHeight)();

  return (
    <div className={clsx(styles.scrollableContent, heightStyle.height)}>
      {props.children}
    </div>
  );
};
