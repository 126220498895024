import {
  Configurations,
  Configuration,
  Rule,
  ConfigurationsValidationSchema,
  ConfigurationValidationSchema
} from '../../domain/Configuration/Configurations';
import {
  BoundedAmountsLateFeeKeys,
  BoundedLesserOfLateFeeKeys,
  FixedAmountLateFeeKeys,
  GeneralKeys,
  FeeKeys,
  GreaterOfLateFeeKeys,
  LesserOfLateFeeKeys,
  LesserOfLateFeeNoteKeys,
  PaymentKeys,
  InventoryDepreciationKeys
} from '../../domain/Configuration/ConfigurationKeys';
import { StateAbbreviation } from '../../domain/Store/State';
import { CurrencyPlacement, NumericInputOptions } from '@rentacenter/racstrap';
export interface ValidationSchema {
  validation: ConfigurationsValidationSchema;
}

const REQUIRED_VALIDATION_MESSAGE = 'Field should not be empty';
const MAX_VALIDATION_MESSAGE = 'Value must be less than or equal to ';
const MIN_VALIDATION_MESSAGE = 'Value must be greater than or equal to ';

type StatesSchemas = {
  [key in StateAbbreviation]?: ConfigurationValidationSchema;
};

interface NumberValidationProps {
  defaultMin: number;
  defaultMax: number;
  rule?: Rule;
  isRequired?: boolean;
  options: any;
}

interface StringValidationProps {
  isRequired?: boolean;
  options: any;
}

const stringValidation = (props: StringValidationProps) => {
  const { isRequired = true, options } = props;
  return {
    value: {
      options,
      required: {
        value: isRequired,
        message: REQUIRED_VALIDATION_MESSAGE
      }
    }
  };
};

const numberValidation = (props: NumberValidationProps) => {
  const { defaultMin, defaultMax, rule, isRequired = true, options } = props;
  const { min, max } = rule
    ? { ...rule }
    : { min: defaultMin, max: defaultMax };
  const fieldMin = min ? Number(min) : defaultMin;
  const fieldMax =
    !!max && Number(max) < Number.MAX_SAFE_INTEGER ? Number(max) : defaultMax;
  const autonumericOptions: NumericInputOptions = {
    ...options
  };
  return {
    value: {
      options: autonumericOptions,
      min: {
        value: fieldMin,
        message: `${MIN_VALIDATION_MESSAGE} ${fieldMin}`
      },
      max: {
        value: fieldMax,
        message: `${MAX_VALIDATION_MESSAGE} ${fieldMax}`
      },
      required: {
        value: isRequired,
        message: REQUIRED_VALIDATION_MESSAGE
      }
    }
  };
};

const generateCountry = (configurations: Configurations) => {
  let result: ConfigurationValidationSchema = {};
  Object.entries<Configuration>(configurations).forEach(item => {
    const abbr = item[0] as StateAbbreviation | 'COUNTRY';
    if (abbr === 'COUNTRY') {
      const stateConfig = item[1];
      result = {
        [GeneralKeys.RentingGroupNumber]: stringValidation({
          options: { maxLength: 16 }
        }),
        [GeneralKeys.RVOnAcceptanceNowTransfer]: numberValidation({
          rule: stateConfig[GeneralKeys.RVOnAcceptanceNowTransfer],
          options: {
            decimalPlaces: 2,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 1.0
        }),
        [FeeKeys.FeeAmount]: numberValidation({
          options: {
            decimalPlaces: 2,
            currencySymbol: '$'
          },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[FeeKeys.FeeAmount]
        }),
        // TODO: UI not implemented
        // [PaymentKeys.DaysUsedToAllowSACOnAgreementPayment]: numberValidation(
        //   4,
        //   5,
        //   stateConfig[PaymentKeys.DaysUsedToAllowSACOnAgreementPayment]
        // ),
        [InventoryDepreciationKeys.InitialWaitPeriodDeferred]: numberValidation(
          {
            options: { decimalPlaces: 0 },
            defaultMin: 0,
            defaultMax: 99999,
            rule:
              stateConfig[InventoryDepreciationKeys.InitialWaitPeriodDeferred]
          }
        ),
        [PaymentKeys.SameAsCashDaysInputs]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0,
          defaultMax: 999,
          rule: stateConfig[PaymentKeys.SameAsCashDaysInputs]
        }),
        [PaymentKeys.SameAsCashDaysPrintedInputs]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0,
          defaultMax: 999,
          rule: stateConfig[PaymentKeys.SameAsCashDaysPrintedInputs]
        })
      };
    }
  });
  return result;
};

const generateStates = (configurations: Configurations): StatesSchemas => {
  const result: StatesSchemas = {};
  Object.entries<Configuration>(configurations).forEach(item => {
    const abbr = item[0] as StateAbbreviation | 'COUNTRY';
    const isValidState = Object.keys(StateAbbreviation).includes(abbr);

    if (abbr !== 'COUNTRY' && isValidState) {
      const stateConfig = item[1];
      result[abbr] = {
        [GeneralKeys.LDWRate]: numberValidation({
          rule: stateConfig[GeneralKeys.LDWRate],
          options: {
            decimalPlaces: 4,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 0.9999
        }),
        [GeneralKeys.LDWMinimumAmountWeekly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMinimumAmountWeekly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMinimumAmountWeekly]
        }),
        [GeneralKeys.LDWMinimumAmountBiWeekly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMinimumAmountBiWeekly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMinimumAmountBiWeekly]
        }),
        [GeneralKeys.LDWMinimumAmountSemiMonthly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMinimumAmountSemiMonthly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMinimumAmountSemiMonthly]
        }),
        [GeneralKeys.LDWMinimumAmountMonthly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMinimumAmountMonthly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMinimumAmountMonthly]
        }),
        [GeneralKeys.LDWMaxAmountWeekly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMaxAmountWeekly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMaxAmountWeekly]
        }),
        [GeneralKeys.LDWMaxAmountBiWeekly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMaxAmountBiWeekly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMaxAmountBiWeekly]
        }),
        [GeneralKeys.LDWMaxAmountSemiMonthly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMaxAmountSemiMonthly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMaxAmountSemiMonthly]
        }),
        [GeneralKeys.LDWMaxAmountMonthly]: numberValidation({
          isRequired: !!stateConfig[GeneralKeys.LDWMaxAmountMonthly],
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GeneralKeys.LDWMaxAmountMonthly]
        }),
        [FeeKeys.Cellphone911FeeAmount]: numberValidation({
          options: {
            decimalPlaces: 4,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 0.1,
          rule: stateConfig[FeeKeys.Cellphone911FeeAmount]
        }),
        [FeeKeys.ChargeBackFee]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[FeeKeys.ChargeBackFee]
        }),
        [FeeKeys.GracePeriodBiWeekly]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodBiWeekly]
        }),
        [FeeKeys.GracePeriodMonthly]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0.0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodMonthly]
        }),
        [FeeKeys.GracePeriodSemiMonthly]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0.0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodSemiMonthly]
        }),
        [FeeKeys.GracePeriodWeekly]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0.0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodWeekly]
        }),
        [FeeKeys.GracePeriodBiWeeklyINote]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodBiWeeklyINote]
        }),
        [FeeKeys.GracePeriodMonthlyINote]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0.0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodMonthlyINote]
        }),
        [FeeKeys.GracePeriodSemiMonthlyINote]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0.0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodSemiMonthlyINote]
        }),
        [FeeKeys.GracePeriodWeeklyINote]: numberValidation({
          options: { decimalPlaces: 0 },
          defaultMin: 0.0,
          defaultMax: 999,
          rule: stateConfig[FeeKeys.GracePeriodWeeklyINote]
        }),
        [BoundedLesserOfLateFeeKeys.LowerLateFeeBound]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[BoundedLesserOfLateFeeKeys.LowerLateFeeBound]
        }),
        [BoundedLesserOfLateFeeKeys.UpperLateFeeBound]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[BoundedLesserOfLateFeeKeys.UpperLateFeeBound]
        }),
        [BoundedLesserOfLateFeeKeys.LateFeePercentage]: numberValidation({
          options: {
            decimalPlaces: 6,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 0.99,
          rule: stateConfig[BoundedLesserOfLateFeeKeys.LateFeePercentage]
        }),
        [FeeKeys.NSFFee]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 999.99,
          rule: stateConfig[FeeKeys.NSFFee]
        }),
        [FeeKeys.PayByPhoneFee]: numberValidation({
          options: { decimalPlaces: 4, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 999.99,
          rule: stateConfig[FeeKeys.PayByPhoneFee]
        }),
        [GreaterOfLateFeeKeys.Percentage]: numberValidation({
          options: {
            decimalPlaces: 6,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 0.99,
          rule: stateConfig[GreaterOfLateFeeKeys.Percentage]
        }),
        [GreaterOfLateFeeKeys.TotalCapPercentage]: numberValidation({
          options: {
            decimalPlaces: 6,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 0.99,
          rule: stateConfig[GreaterOfLateFeeKeys.TotalCapPercentage]
        }),
        [GreaterOfLateFeeKeys.AmountMonthly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GreaterOfLateFeeKeys.AmountMonthly]
        }),
        [GreaterOfLateFeeKeys.AmountWeekly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[GreaterOfLateFeeKeys.AmountWeekly]
        }),
        [LesserOfLateFeeKeys.Percentage]: numberValidation({
          options: {
            decimalPlaces: 6,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 0.99,
          rule: stateConfig[LesserOfLateFeeKeys.Percentage]
        }),
        [LesserOfLateFeeKeys.AmountMonthly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[LesserOfLateFeeKeys.AmountMonthly]
        }),
        [LesserOfLateFeeKeys.AmountWeekly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[LesserOfLateFeeKeys.AmountWeekly]
        }),
        [LesserOfLateFeeNoteKeys.Percentage]: numberValidation({
          options: {
            decimalPlaces: 6,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 0.99,
          rule: stateConfig[LesserOfLateFeeKeys.Percentage]
        }),
        [LesserOfLateFeeNoteKeys.AmountWeekly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[LesserOfLateFeeNoteKeys.AmountWeekly]
        }),
        [LesserOfLateFeeNoteKeys.AmountMonthly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[LesserOfLateFeeNoteKeys.AmountMonthly]
        }),
        [LesserOfLateFeeNoteKeys.InstallmentAmount]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[LesserOfLateFeeNoteKeys.InstallmentAmount]
        }),
        [FixedAmountLateFeeKeys.Weekly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[FixedAmountLateFeeKeys.Weekly]
        }),
        [FixedAmountLateFeeKeys.Monthly]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[FixedAmountLateFeeKeys.Monthly]
        }),
        [BoundedAmountsLateFeeKeys.Weekly1]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[BoundedAmountsLateFeeKeys.Weekly1]
        }),
        [BoundedAmountsLateFeeKeys.Weekly2]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[BoundedAmountsLateFeeKeys.Weekly2]
        }),
        [BoundedAmountsLateFeeKeys.WeeklyBoundary]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[BoundedAmountsLateFeeKeys.WeeklyBoundary]
        }),
        [BoundedAmountsLateFeeKeys.Monthly1]: numberValidation({
          options: { decimalPlaces: 2, currencySymbol: '$' },
          defaultMin: 0.0,
          defaultMax: 99.99,
          rule: stateConfig[BoundedAmountsLateFeeKeys.Monthly1]
        }),
        [PaymentKeys.EPOPercentCashPriceRentPaid]: numberValidation({
          options: {
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 1.0,
          rule: stateConfig[PaymentKeys.EPOPercentCashPriceRentPaid]
        }),
        [PaymentKeys.EPOPercentRemainingRent]: numberValidation({
          options: {
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 1.0,
          rule: stateConfig[PaymentKeys.EPOPercentRemainingRent]
        }),
        [PaymentKeys.EPORentPaidAcquisitionCost]: numberValidation({
          rule: stateConfig[PaymentKeys.EPORentPaidAcquisitionCost],
          options: { decimalPlaces: 0 },
          defaultMin: 0,
          defaultMax: 99_999
        }),
        [PaymentKeys.TotalCostLimit]: numberValidation({
          options: {
            decimalPlaces: 4,
            currencySymbol: '%',
            currencySymbolPlacement: CurrencyPlacement.suffix
          },
          defaultMin: 0.0,
          defaultMax: 999.9999,
          rule: stateConfig[PaymentKeys.TotalCostLimit]
        })
      };
    }
  });
  return result;
};

export const validation = (
  configurations: Configurations
): ConfigurationsValidationSchema => {
  return {
    COUNTRY: generateCountry(configurations),
    ...generateStates(configurations)
  };
};
