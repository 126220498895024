import { State, StateAbbreviation } from '../domain/Store/State';

export const usStates: State[] = [
  {
    name: 'Alabama',
    abbreviation: StateAbbreviation.AL
  },
  {
    name: 'Alaska',
    abbreviation: StateAbbreviation.AK
  },
  {
    name: 'Arizona',
    abbreviation: StateAbbreviation.AZ
  },
  {
    name: 'Arkansas',
    abbreviation: StateAbbreviation.AR
  },
  {
    name: 'California',
    abbreviation: StateAbbreviation.CA
  },
  {
    name: 'Colorado',
    abbreviation: StateAbbreviation.CO
  },
  {
    name: 'Connecticut',
    abbreviation: StateAbbreviation.CT
  },
  {
    name: 'Delaware',
    abbreviation: StateAbbreviation.DE
  },
  {
    name: 'District of Columbia',
    abbreviation: StateAbbreviation.DC
  },
  {
    name: 'Florida',
    abbreviation: StateAbbreviation.FL
  },
  {
    name: 'Georgia',
    abbreviation: StateAbbreviation.GA
  },
  {
    name: 'Hawaii',
    abbreviation: StateAbbreviation.HI
  },
  {
    name: 'Idaho',
    abbreviation: StateAbbreviation.ID
  },
  {
    name: 'Illinois',
    abbreviation: StateAbbreviation.IL
  },
  {
    name: 'Indiana',
    abbreviation: StateAbbreviation.IN
  },
  {
    name: 'Iowa',
    abbreviation: StateAbbreviation.IA
  },
  {
    name: 'Kansas',
    abbreviation: StateAbbreviation.KS
  },
  {
    name: 'Kentucky',
    abbreviation: StateAbbreviation.KY
  },
  {
    name: 'Louisiana',
    abbreviation: StateAbbreviation.LA
  },
  {
    name: 'Maine',
    abbreviation: StateAbbreviation.ME
  },
  {
    name: 'Maryland',
    abbreviation: StateAbbreviation.MD
  },
  {
    name: 'Massachusetts',
    abbreviation: StateAbbreviation.MA
  },
  {
    name: 'Michigan',
    abbreviation: StateAbbreviation.MI
  },
  {
    name: 'Minnesota',
    abbreviation: StateAbbreviation.MN
  },
  {
    name: 'Mississippi',
    abbreviation: StateAbbreviation.MS
  },
  {
    name: 'Missouri',
    abbreviation: StateAbbreviation.MO
  },
  {
    name: 'Montana',
    abbreviation: StateAbbreviation.MT
  },
  {
    name: 'Nebraska',
    abbreviation: StateAbbreviation.NE
  },
  {
    name: 'Nevada',
    abbreviation: StateAbbreviation.NV
  },
  {
    name: 'New Hampshire',
    abbreviation: StateAbbreviation.NH
  },
  {
    name: 'New Jersey',
    abbreviation: StateAbbreviation.NJ
  },
  {
    name: 'New Mexico',
    abbreviation: StateAbbreviation.NM
  },
  {
    name: 'New York',
    abbreviation: StateAbbreviation.NY
  },
  {
    name: 'North Carolina',
    abbreviation: StateAbbreviation.NC
  },
  {
    name: 'North Dakota',
    abbreviation: StateAbbreviation.ND
  },
  {
    name: 'Ohio',
    abbreviation: StateAbbreviation.OH
  },
  {
    name: 'Oklahoma',
    abbreviation: StateAbbreviation.OK
  },
  {
    name: 'Oregon',
    abbreviation: StateAbbreviation.OR
  },
  {
    name: 'Pennsylvania',
    abbreviation: StateAbbreviation.PA
  },
  {
    name: 'Rhode Island',
    abbreviation: StateAbbreviation.RI
  },
  {
    name: 'South Carolina',
    abbreviation: StateAbbreviation.SC
  },
  {
    name: 'South Dakota',
    abbreviation: StateAbbreviation.SD
  },
  {
    name: 'Tennessee',
    abbreviation: StateAbbreviation.TN
  },
  {
    name: 'Texas',
    abbreviation: StateAbbreviation.TX
  },
  {
    name: 'Utah',
    abbreviation: StateAbbreviation.UT
  },
  {
    name: 'Vermont',
    abbreviation: StateAbbreviation.VT
  },
  {
    name: 'Virginia',
    abbreviation: StateAbbreviation.VA
  },
  {
    name: 'Washington',
    abbreviation: StateAbbreviation.WA
  },
  {
    name: 'West Virginia',
    abbreviation: StateAbbreviation.WV
  },
  {
    name: 'Wisconsin',
    abbreviation: StateAbbreviation.WI
  },
  {
    name: 'Wyoming',
    abbreviation: StateAbbreviation.WY
  },
  // unincorporated teritories
  {
    name: 'American Samoa',
    abbreviation: StateAbbreviation.AS
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: StateAbbreviation.FM
  },
  {
    name: 'Guam',
    abbreviation: StateAbbreviation.GU
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: StateAbbreviation.MP
  },
  {
    name: 'Palau',
    abbreviation: StateAbbreviation.PW
  },
  {
    name: 'Puerto Rico',
    abbreviation: StateAbbreviation.PR
  },
  {
    name: 'Virgin Islands',
    abbreviation: StateAbbreviation.VI
  },
  {
    name: 'Armed Forces Europe',
    abbreviation: StateAbbreviation.AE
  }
];

export function getNameForAbbreviation(
  abbreviation: StateAbbreviation
): string | undefined {
  return usStates.find(state => state.abbreviation === abbreviation)?.name;
}
