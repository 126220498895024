import { Dispatch } from 'react';

import { getStores } from '../../api/api';
import { Store } from '../../domain/Store/Store';
import {
  ApiAction,
  getErrorAction,
  getLoadingAction,
  getSuccessAction
} from '../api-actions';

export interface StoresThunks {
  getStores(): Promise<void>;
}

export const getStoresThunk = (dispatch: Dispatch<ApiAction>) => async () => {
  try {
    dispatch(getLoadingAction());

    const storeList: Store[] = await getStores();

    dispatch(getSuccessAction(storeList));
  } catch (error) {
    dispatch(getErrorAction(error));
  }
};
