import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { FeeKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { TooltipItem } from '../../../../../common/TooltipItem';
import { AutoNumeric } from '../../../../../common/form/auto-numeric/AutoNumeric';
import { ErrorIcon } from '../../../../../common/form/error-icon/ErrorIcon';
import { useConfigurationsValidationContext } from '../../../../../../context/configurations/configurations-contexts';
import { StateFeesProps } from './StateFees';

export const Cellphone911FeeAmount = (props: StateFeesProps) => {
  const { currentStateAbbr } = props;
  const { errors } = useFormContext();
  const validation = useConfigurationsValidationContext();

  const fieldName = `${currentStateAbbr}.${FeeKeys.Cellphone911FeeAmount}.value`;

  const fieldError = get(errors, fieldName);
  const { options } = get(validation, fieldName);

  return (
    <TooltipItem
      title={fieldError?.message}
      arrow
      placement="bottom-start"
      TransitionProps={{
        exit: false
      }}
    >
      <Controller
        as={AutoNumeric}
        name={fieldName}
        options={options}
        invalid={!!fieldError}
        icon={!!fieldError && <ErrorIcon />}
        ariaLabelledby={FeeKeys.Cellphone911FeeAmount}
        size="small"
        disabled
      />
    </TooltipItem>
  );
};
