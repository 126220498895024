import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleRight,
  faAngleDoubleLeft
} from '@fortawesome/free-solid-svg-icons';

import styles from './SelectionPreviewCollapse.module.scss';
import clsx from 'clsx';
import { Card } from '@rentacenter/racstrap';

export const selectionPreviewCollapseTestId = 'selectionPreviewCollapseTestId';

export const SelectionPreviewCollapse = (
  props: PropsWithChildren<{
    onHandleClick: () => void;
    isCollapsed: boolean;
  }>
) => {
  const { children, onHandleClick, isCollapsed } = props;

  return (
    <div data-testid={selectionPreviewCollapseTestId}>
      {isCollapsed && (
        <div className={styles.buttonWrapper}>
          <span onClick={onHandleClick} className={styles.toggleButton}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </span>
        </div>
      )}
      <Card className={clsx(styles.card, { [styles.collapsed]: isCollapsed })}>
        <span onClick={onHandleClick} className={styles.toggleButton}>
          <span className={styles.btnTitle}>Hide panel</span>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </span>
        <div className={styles.content}>{children}</div>
      </Card>
    </div>
  );
};
