import React, { FC } from 'react';

import classes from './SelectStores.module.scss';

import { SelectStoresByEntity } from './SelectStoresByEntity';
import { SelectStoresByState } from './SelectStoresByState';
import { Footer } from '../layout/footer/Footer';
import { SelectStoresNextButton } from './SelectStoresNextButton';
import { Tabs } from '@rentacenter/racstrap';
import {
  useConfigurationsStateContext,
  useConfigurationsDispatchContext
} from '../../context/configurations/configurations-contexts';
import { SelectCriteria } from '../../context/configurations/selected-stores/selected-stores-reducer';
import { setInitialStateAction } from '../../context/configurations/selected-stores/selected-stores-actions';

export const selectStoresTestId = 'selectStoresTestId';

export const SelectStores: FC = () => {
  const {
    selectedStores: { selectedCriteria }
  } = useConfigurationsStateContext();

  const dispatch = useConfigurationsDispatchContext();
  const selectedTab = selectedCriteria === SelectCriteria.ByEntity ? 0 : 1;

  return (
    <div data-testid={selectStoresTestId} className={classes.mainContainer}>
      <Tabs
        value={selectedTab}
        onChange={() => dispatch(setInitialStateAction(selectedCriteria))}
      >
        <div title="Stores by Entities">
          <SelectStoresByEntity />
        </div>
        <div title="Stores by State">
          <SelectStoresByState />
        </div>
      </Tabs>

      <Footer>
        <SelectStoresNextButton />
      </Footer>
    </div>
  );
};
