import React, { useContext } from 'react';
import { get } from 'lodash';
import { useFormContext, Controller } from 'react-hook-form';

import { GeneralKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { ErrorIcon } from '../../../../../common/form/error-icon/ErrorIcon';
import { AutoNumeric } from '../../../../../common/form/auto-numeric/AutoNumeric';
import {
  useConfigurationsStateContext,
  useConfigurationsValidationContext
} from '../../../../../../context/configurations/configurations-contexts';
import { FieldChangedContext } from '../../../FieldChangedContext';

export interface Props {
  readonly disabled?: boolean;
  readonly currentStateAbbr: StateAbbreviation;
}

export const LDWRate = (props: Props) => {
  const { disabled, currentStateAbbr } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { errors } = useFormContext();
  const { fieldChanged } = useContext(FieldChangedContext);
  const validation = useConfigurationsValidationContext();

  const ruleName = `${currentStateAbbr}.${GeneralKeys.LDWRate}`;
  const fieldName = `${ruleName}.value`;

  const presentInApi = !!get(initialConfigurations, ruleName);
  const changed = fieldChanged(fieldName);
  const fieldError = get(errors, fieldName);
  const { options, ...rules } = get(validation, fieldName);

  return (
    <Controller
      as={AutoNumeric}
      name={fieldName}
      options={options}
      label="LDW % rate"
      rules={presentInApi && rules}
      size="small"
      errorMessage={fieldError?.message}
      disabled={disabled || !presentInApi}
      invalid={!!fieldError}
      icon={!!fieldError && <ErrorIcon />}
      changed={changed}
    />
  );
};
