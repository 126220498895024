import { useEffect } from 'react';
import { useStoresThunksContext } from '../../context/stores/stores-context';

export const StoresFetcher = () => {
  const { getStores } = useStoresThunksContext();

  useEffect(() => {
    getStores();
  }, [getStores]);

  return null;
};
