import { Action } from './Action';

export enum ApiActionType {
  RESET = 'RESET',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export type ApiAction = Action<ApiActionType>;

export const getResetAction = (): ApiAction => ({ type: ApiActionType.RESET });

export const getLoadingAction = (): ApiAction => ({
  type: ApiActionType.LOADING
});

export const getSuccessAction = (payload: any): ApiAction => ({
  type: ApiActionType.SUCCESS,
  payload
});

export const getErrorAction = (error: any): ApiAction => ({
  type: ApiActionType.ERROR,
  payload: error
});
