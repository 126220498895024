import React, { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { get, set, unset } from 'lodash';

import styles from '../../Fees.module.scss';

import {
  BoundedAmountsLateFeeKeys,
  BoundedLesserOfLateFeeKeys,
  ConfigurationKeys,
  FixedAmountLateFeeKeys,
  GreaterOfLateFeeKeys,
  LesserOfLateFeeKeys,
  LesserOfLateFeeNoteKeys,
  lateFeeKeys
} from '../../../../../../../domain/Configuration/ConfigurationKeys';
import { Select } from '../../../../../../common/form/select/Select';
import {
  useConfigurationsDispatchContext,
  useConfigurationsStateContext
} from '../../../../../../../context/configurations/configurations-contexts';
import {
  countUniqRules,
  getUniqRules
} from '../../../../../../../context/configurations/configurations-selectors';
import { changeConfigurationsAction } from '../../../../../../../context/configurations/changed-configurations/changed-configurations-actions';
import { GreaterOfLateFee } from '../../greater-of-late-fee/GreaterOfLateFee';
import { LesserOfLateFee } from '../../lesser-of-late-fee/LesserOfLateFee';
import { LesserOfLateFeeNote } from '../../lesser-of-late-fee-note/LesserOfLateFeeNote';
import { FixedAmountLateFee } from '../../fixed-amount-late-fee/FixedAmountLateFee';
import { BoundedAmountsLateFee } from '../../bounded-amounts-late-fee/BoundedAmountsLateFee';
import { BoundedLesserOfLateFee } from '../../bounded-lesser-of-late-fee/BoundedLesserOfLateFee';
import { StateFeesProps } from '../StateFees';
import { StateAbbreviation } from '../../../../../../../domain/Store/State';

export interface LateFeeCommonProps {
  readonly disabled?: boolean;
  readonly stateAbbr: StateAbbreviation;
  readonly freshStrategy: boolean;
}

export const LateFeeAmount = (props: StateFeesProps) => {
  const { currentStateAbbr, configurations, disabled } = props;
  const dispatch = useConfigurationsDispatchContext();
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();

  const fieldName = `${currentStateAbbr}.monthlyStrategy`;

  const strategies: Record<string, any>[] = [
    {
      label: 'Greater Of Late Fee',
      value: 'GreaterOfLateFeeStrategy',
      enum: GreaterOfLateFeeKeys
    },
    {
      label: 'Lesser Of Late Fee',
      value: 'LesserOfLateFeeStrategy',
      enum: LesserOfLateFeeKeys
    },
    {
      label: 'Lesser Of Late Fee Note',
      value: 'LesserOfLateFeeStrategyNote',
      enum: LesserOfLateFeeNoteKeys
    },
    {
      label: 'Fixed Amount Late Fee',
      value: 'FixedAmountLateFeeStrategy',
      enum: FixedAmountLateFeeKeys
    },
    {
      label: 'Bounded Amounts Late Fee',
      value: 'BoundedAmountsLateFeeStrategy',
      enum: BoundedAmountsLateFeeKeys
    },
    {
      label: 'Bounded Lesser Of Late Fee',
      value: 'BoundedLesserOfLateFeeStrategy',
      enum: BoundedLesserOfLateFeeKeys
    }
  ];

  const initialConfig = {
    [currentStateAbbr]: (initialConfigurations || {})[currentStateAbbr],
    COUNTRY: {}
  };
  const currentConfig = {
    [currentStateAbbr]: (configurations || {})[currentStateAbbr],
    COUNTRY: {}
  };

  const initialStrategy = strategies.find(
    strategy =>
      countUniqRules(initialConfig)(Object.values(strategy.enum)) !== 0
  )?.value;
  const defaultValue =
    (configurations[currentStateAbbr] &&
      configurations[currentStateAbbr]?.monthlyStrategy) ||
    initialStrategy;
  const selectedStrategy = useWatch({ name: fieldName, defaultValue });

  useEffect(
    function clearUnselectedStrategies() {
      const inValidLateFeeKeys = getUniqRules(currentConfig)(
        lateFeeKeys
      ).filter(key => !key.includes(selectedStrategy));
      const selectedStrategyRules = Object.values(
        strategies.find(strategy => strategy.value === selectedStrategy)?.enum
      ) as ConfigurationKeys[];
      const isSelectedStrategyKeyPresent =
        countUniqRules(currentConfig)(selectedStrategyRules) !== 0;

      if (selectedStrategy !== '' && currentStateAbbr && configurations) {
        inValidLateFeeKeys.forEach(key => {
          unset(configurations[currentStateAbbr], key);
        });
        if (
          !isSelectedStrategyKeyPresent &&
          initialStrategy === selectedStrategy
        ) {
          (selectedStrategyRules as string[]).forEach(key => {
            set(
              configurations[currentStateAbbr] || {},
              key,
              get(initialConfig[currentStateAbbr], key)
            );
          });
        }

        if (inValidLateFeeKeys.length > 0) {
          dispatch(changeConfigurationsAction(configurations, true));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStrategy]
  );

  return (
    <>
      <Controller
        as={Select}
        size="small"
        className={styles.feesStrategiesSelect}
        label="Select Strategy"
        placeholder="Select Strategy"
        name={fieldName}
        options={strategies.map(({ label, value }) => ({ label, value }))}
        disabled={true}
        changed={selectedStrategy !== initialStrategy && disabled}
        defaultValue={defaultValue}
      />

      {
        ({
          GreaterOfLateFeeStrategy: <GreaterOfLateFee {...props} />,
          LesserOfLateFeeStrategy: <LesserOfLateFee {...props} />,
          LesserOfLateFeeStrategyNote: <LesserOfLateFeeNote {...props} />,
          FixedAmountLateFeeStrategy: <FixedAmountLateFee {...props} />,
          BoundedAmountsLateFeeStrategy: <BoundedAmountsLateFee {...props} />,
          BoundedLesserOfLateFeeStrategy: <BoundedLesserOfLateFee {...props} />
        } as Record<string, any>)[selectedStrategy]
      }
    </>
  );
};
