import React from 'react';
import get from 'lodash/get';

import { PaymentKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { Switch } from '../../../../common/form/switch/Switch';
import { Configurations } from '../../../../../domain/Configuration/Configurations';

export interface Props {
  readonly configurations: Configurations;
}

export const PrintEPOHistory = (props: Props) => {
  const { configurations } = props;

  const fieldValue = `COUNTRY.${PaymentKeys.PrintEPOHistory}.value`;

  return (
    <>
      <Switch
        label="Print EPO History"
        name={fieldValue}
        defaultChecked={get(configurations, fieldValue)}
        disabled
        isValueVisible
      />
    </>
  );
};
