import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { ToastDisplay } from './ToastDisplay';

import styles from './ToastDisplay.module.scss';

const ToastStateContext = createContext<ToastState | undefined>(undefined);
const ToastDispatchContext = createContext<Dispatch | undefined>(undefined);

type ToastState = {
  type?: ToastType;
  message?: string;
  duration?: number;
  isVisible: boolean;
};

export enum ActionType {
  SHOW = 'SHOW',
  HIDE = 'HIDE'
}

type Action =
  | {
      type: ActionType.SHOW;
      payload: {
        type: ToastType;
        message: string;
        duration?: number;
      };
    }
  | {
      type: ActionType.HIDE;
    };

type Dispatch = (action: Action) => void;

interface ToastProps {
  children?: ReactNode;
}

export enum ToastType {
  Info = 'INFO',
  Error = 'ERROR',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

const reducer = (state: ToastState, action: Action): ToastState => {
  switch (action.type) {
    case ActionType.SHOW:
      return !action.payload.duration
        ? {
            ...state,
            type: action.payload.type,
            message: action.payload.message,
            isVisible: true
          }
        : {
            ...state,
            type: action.payload.type,
            message: action.payload.message,
            duration: action.payload.duration,
            isVisible: true
          };
    case ActionType.HIDE:
      return {
        ...state,
        isVisible: false
      };
  }
};

const ToastProvider = ({ children }: ToastProps) => {
  const [state, dispatch] = useReducer<React.Reducer<ToastState, Action>>(
    reducer,
    {
      isVisible: false
    }
  );

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        <div className={styles.toastWrapper}>
          <ReactCSSTransitionGroup
            transitionName={{
              enter: styles.toastEnter,
              enterActive: styles.toastEnterActive,
              leave: styles.toastLeave,
              leaveActive: styles.toastLeaveActive
            }}
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
          >
            {state.isVisible && <ToastDisplay />}
          </ReactCSSTransitionGroup>
        </div>
        {children}
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
};

const useToastState = () => {
  const context = useContext(ToastStateContext);
  if (context === undefined) {
    throw new Error('useToastState must be used within a ToastProvider');
  }
  return context;
};

const useToastDispatch = () => {
  const dispatch = useContext(ToastDispatchContext);
  if (dispatch === undefined) {
    throw new Error('useToastDispatch must be used within a ToastProvider');
  }
  return dispatch;
};

const useToastActionsContext = () => {
  const dispatch = useToastDispatch();

  const showToast = (
    type = ToastType.Success,
    message: string,
    duration = 5000
  ) => {
    dispatch({
      type: ActionType.SHOW,
      payload: { message, type, duration: duration }
    });
  };

  const hideToast = () => {
    dispatch({ type: ActionType.HIDE });
  };

  return { showToast, hideToast };
};

export { ToastProvider, useToastState, useToastActionsContext };
