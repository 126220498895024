import React from 'react';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { AutoNumeric } from '../../../../common/form/auto-numeric/AutoNumeric';
import { GeneralKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import {
  useConfigurationsStateContext,
  useConfigurationsValidationContext
} from '../../../../../context/configurations/configurations-contexts';

export const RVOnAcceptanceNowTransfer = () => {
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { control, errors } = useFormContext();
  const validation = useConfigurationsValidationContext();

  const ruleName = `COUNTRY.${GeneralKeys.RVOnAcceptanceNowTransfer}`;
  const fieldName = `${ruleName}.value`;

  const presentInApi = !!get(initialConfigurations, ruleName);
  const fieldError = get(errors, fieldName);
  const { options, ...rules } = get(validation, fieldName);

  return (
    <Controller
      as={AutoNumeric}
      control={control}
      name={fieldName}
      placeholder="RV % on Acima Transfers"
      size="small"
      invalid={!!fieldError}
      errorMessage={fieldError?.message}
      disabled
      rules={presentInApi && rules}
      options={options}
    />
  );
};
