import React, { useContext, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { get } from 'lodash';

import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { TooltipItem } from '../../../../../common/TooltipItem';
import { ErrorIcon } from '../../../../../common/form/error-icon/ErrorIcon';
import { AutoNumeric } from '../../../../../common/form/auto-numeric/AutoNumeric';
import { GeneralKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import {
  useConfigurationsStateContext,
  useConfigurationsValidationContext
} from '../../../../../../context/configurations/configurations-contexts';
import { FieldChangedContext } from '../../../FieldChangedContext';

export interface Props {
  readonly disabled?: boolean;
  readonly currentStateAbbr: StateAbbreviation;
}

export const LDWMinAmountMonthly = (props: Props) => {
  const { disabled, currentStateAbbr } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { errors } = useFormContext();
  const { fieldChanged } = useContext(FieldChangedContext);
  const validation = useConfigurationsValidationContext();

  const ruleName = `${currentStateAbbr}.${GeneralKeys.LDWMinimumAmountMonthly}`;
  const fieldName = `${ruleName}.value`;

  const presentInApi = !!get(initialConfigurations, ruleName);
  const changed = fieldChanged(fieldName);
  const { options, ...rules } = get(validation, fieldName);
  const fieldError = get(errors, fieldName);

  return useMemo(
    () => (
      <TooltipItem
        title={fieldError?.message}
        arrow
        placement="bottom-start"
        TransitionProps={{
          exit: false
        }}
      >
        <Controller
          as={AutoNumeric}
          name={fieldName}
          options={options}
          rules={presentInApi && rules}
          size="small"
          disabled={disabled || !presentInApi}
          invalid={!!fieldError}
          icon={!!fieldError && <ErrorIcon />}
          changed={changed}
        />
      </TooltipItem>
    ),
    [presentInApi, fieldName, fieldError, disabled, changed, options, rules]
  );
};
