import { ApiActionType } from '../api-actions';
import { ConfigurationsAction } from './configurations-reducer';

export interface SubmitConfigurationsState {
  success?: boolean;
  isPending: boolean;
  error?: any;
}

export const initialState: SubmitConfigurationsState = {
  success: false,
  isPending: false
};

export const submitConfigurationsReducer = (
  state: SubmitConfigurationsState,
  action: ConfigurationsAction
): SubmitConfigurationsState => {
  switch (action.type) {
    case ApiActionType.RESET:
      return initialState;
    case ApiActionType.LOADING:
      return {
        success: false,
        isPending: true,
        error: undefined
      };

    case ApiActionType.SUCCESS:
      return {
        success: true,
        isPending: false,
        error: undefined
      };

    case ApiActionType.ERROR:
      return {
        success: false,
        isPending: false,
        error: action.payload
      };

    default:
      return state;
  }
};
