import React, { useEffect, useState } from 'react';
import { Button } from '@rentacenter/racstrap';

import {
  Modal,
  ModalButtons,
  ModalContent
} from '../../../../common/modal/Modal';
import { PaymentKeys } from '../../../../../domain/Configuration/ConfigurationKeys';

export const dismissBtnTestId = 'dismissBtnTestId';

export interface ErrorModalProps {
  errors: any;
}

export const getFirstError = (errors: any) => {
  if (!errors) {
    return '';
  }

  const firstStateKey = Object.keys(errors)[0];
  const firstFieldKey = Object.keys(errors[firstStateKey])[0];

  if (
    firstFieldKey === PaymentKeys.SameAsCashDaysInputs ||
    firstFieldKey === PaymentKeys.SameAsCashDaysPrintedInputs
  ) {
    const firstRowKey = Object.keys(
      errors[firstStateKey][firstFieldKey].value.sameascash
    )[0];

    return `${firstStateKey}.${firstFieldKey}.value.sameascash[${firstRowKey}].sac`;
  }

  return `${firstStateKey}.${firstFieldKey}.value`;
};

export const ErrorModal = ({ errors }: ErrorModalProps) => {
  const [firstError, setFirstError] = useState('');

  const dismissErrorModal = () => {
    const element = document.getElementById(firstError);
    if (element) {
      element.scrollIntoView();
    }
    setFirstError('');
  };

  useEffect(() => {
    setFirstError(getFirstError(errors));
  }, [errors]);

  return (
    <Modal
      isOpen={!!firstError}
      onClose={() => setFirstError('')}
      title="Form is invalid"
    >
      <ModalContent>Please fix the form errors before proceeding</ModalContent>
      <ModalButtons>
        <Button
          data-testid={dismissBtnTestId}
          color="primary"
          onClick={dismissErrorModal}
        >
          Ok
        </Button>
      </ModalButtons>
    </Modal>
  );
};
