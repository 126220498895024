import React, { useReducer, ReactNode, useMemo } from 'react';

import {
  configurationsInitialState,
  configurationsReducer
} from './configurations-reducer';
import {
  ConfigurationsStateContext,
  ConfigurationsDispatchContext,
  ConfigurationsSelectorsContext,
  ConfigurationsThunksContext,
  ConfigurationsValidatorContext
} from './configurations-contexts';
import { SelectedStoresSelectorsContext } from './selected-stores/selected-stores-context';
import {
  ConfigurationsSelectors,
  countUniqRules,
  haveConfigurationsChanged,
  statesAbbrsForRules,
  statesAbbrsSelectedList
} from './configurations-selectors';
import {
  SelectedStoresSelectors,
  arePresent,
  firstStore,
  firstStoresNums,
  uniqueStates
} from './selected-stores/selected-stores-selectors';

import {
  ConfigurationsThunks,
  getInitialConfigurationsThunk,
  submitConfigurationsThunk
} from './configurations-thunks';
import {
  Configurations,
  ConfigurationsValidationSchema
} from '../../domain/Configuration/Configurations';
import { validation } from './configurations-validator';

export const ConfigurationsProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    configurationsReducer,
    configurationsInitialState
  );

  const {
    initialConfigurations: { configurations: initialConfigurations },
    changedConfigurations
  } = state;

  const validationSchema: ConfigurationsValidationSchema = useMemo(
    () => validation(initialConfigurations || ({} as Configurations)),
    [initialConfigurations]
  );

  const configurationsSelectors: ConfigurationsSelectors = {
    haveConfigurationsChanged: haveConfigurationsChanged(
      initialConfigurations,
      changedConfigurations
    ),
    statesAbbrsForRules: statesAbbrsForRules(initialConfigurations),
    statesAbbrsSelectedList: statesAbbrsSelectedList(initialConfigurations),
    countUniqRules: countUniqRules(initialConfigurations)
  };

  const selectedStoresSelectors: SelectedStoresSelectors = {
    arePresent: arePresent(state.selectedStores),
    firstStoresNums: firstStoresNums(state.selectedStores),
    firstStore: firstStore(state.selectedStores),
    uniqueStates: uniqueStates(state.selectedStores)
  };

  const thunks: ConfigurationsThunks = useMemo(
    () => ({
      getInitialConfigurations: getInitialConfigurationsThunk(dispatch),
      submitConfigurations: submitConfigurationsThunk(
        dispatch,
        state.selectedStores
      )
    }),
    [dispatch, state.selectedStores]
  );

  return (
    <ConfigurationsStateContext.Provider value={state}>
      <ConfigurationsValidatorContext.Provider value={validationSchema}>
        <ConfigurationsSelectorsContext.Provider
          value={configurationsSelectors}
        >
          <SelectedStoresSelectorsContext.Provider
            value={selectedStoresSelectors}
          >
            <ConfigurationsDispatchContext.Provider value={dispatch}>
              <ConfigurationsThunksContext.Provider value={thunks}>
                {props.children}
              </ConfigurationsThunksContext.Provider>
            </ConfigurationsDispatchContext.Provider>
          </SelectedStoresSelectorsContext.Provider>
        </ConfigurationsSelectorsContext.Provider>
      </ConfigurationsValidatorContext.Provider>
    </ConfigurationsStateContext.Provider>
  );
};
