import * as React from 'react';
import { useCallback, useMemo } from 'react';

import { useStoresSelectorsContext } from '../../../context/stores/stores-context';
import {
  useConfigurationsDispatchContext,
  useConfigurationsStateContext
} from '../../../context/configurations/configurations-contexts';
import { StoreListItemSelectEvent } from './store-list-item/StoreListItem';
import {
  deselectStoresAction,
  selectStoresAction
} from '../../../context/configurations/selected-stores/selected-stores-actions';
import { StoreList } from './StoreList';
import {
  SelectCriteria,
  StoresByCriteria
} from '../../../context/configurations/selected-stores/selected-stores-reducer';

import styles from './StoreListByEntityOrState.module.scss';

export const storeListByStateTestId = 'storeListByStateTestId';

export const StoreListByState = () => {
  const { getStoresByState } = useStoresSelectorsContext();

  const {
    selectedStores: { selectedStores }
  } = useConfigurationsStateContext();

  const dispatch = useConfigurationsDispatchContext();

  const stores: StoresByCriteria = getStoresByState();

  const handleItemCheck = useCallback(
    (e: StoreListItemSelectEvent) => {
      const { checked, criteriaName } = e;

      dispatch(
        checked
          ? selectStoresAction({
              allStores: stores,
              selectedCriteria: SelectCriteria.ByState,
              selectedStores: { [criteriaName]: stores[criteriaName] }
            })
          : deselectStoresAction(criteriaName)
      );
    },
    [dispatch, stores]
  );

  return useMemo(
    () => (
      <div data-testid={storeListByStateTestId}>
        <div className={styles.storeListWrapper}>
          <StoreList
            stores={stores}
            selectedStores={selectedStores}
            onItemCheck={handleItemCheck}
          />
        </div>
      </div>
    ),
    [stores, selectedStores, handleItemCheck]
  );
};
