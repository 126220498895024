import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { useConfigurationsValidationContext } from '../../../../../../context/configurations/configurations-contexts';
import { AutoNumeric } from '../../../../../common/form/auto-numeric/AutoNumeric';
import { FieldChangedContext } from '../../../FieldChangedContext';

interface Props {
  fieldname: string;
  prevFieldName?: string;
  sacKey: string;
  disabled?: boolean;
}

export const SACInput = ({
  fieldname,
  sacKey,
  disabled,
  prevFieldName
}: Props) => {
  const validation = useConfigurationsValidationContext();
  const { options, ...rules } = get(validation, `COUNTRY.${sacKey}.value`);
  const { errors, getValues } = useFormContext();
  const { fieldChanged } = useContext(FieldChangedContext);

  const error = get(errors, fieldname);
  const changed = fieldChanged(fieldname);

  rules.validate = (value: string) => {
    if (!prevFieldName) {
      return true;
    }
    const currentValue = parseInt(value, 10);
    const prevValue = parseInt(get(getValues(), prevFieldName), 10);
    const isValid = currentValue > prevValue;
    if (!isValid) {
      return 'Value must be bigger than previous';
    }
    return true;
  };

  return (
    <Controller
      as={AutoNumeric}
      name={fieldname}
      options={options}
      rules={rules}
      size="small"
      disabled={disabled}
      errorMessage={error?.message}
      changed={changed}
    />
  );
};
