import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rentacenter/racstrap';
import { useConfigurationsStateContext } from '../../context/configurations/configurations-contexts';
import { ConfigurationsRoute } from '../../config/route-config';

export const editConfigsNextButtonTestId = 'editConfigsNextButtonTestId';

export const EditConfigsNextButton = withRouter(
  ({ history }: RouteComponentProps) => {
    const {
      initialConfigurations: { isPending }
    } = useConfigurationsStateContext();

    const handleClick = useCallback(() => {
      history.push(ConfigurationsRoute.Save);
    }, [history]);

    return useMemo(
      () => (
        <Button
          color="primary"
          disabled={isPending}
          onClick={handleClick}
          data-testid={editConfigsNextButtonTestId}
        >
          Next
        </Button>
      ),
      [isPending, handleClick]
    );
  }
);
