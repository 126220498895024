import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';

import { SelectedStoresSelectorsContext } from '../../context/configurations/selected-stores/selected-stores-context';
import { ScrollableContent } from '../common/scrollable-content/ScrollableContent';
import { Footer } from '../layout/footer/Footer';
import { ConfigBackButton } from '../edit-configs/ConfigBackButton';
import { ConfigurationsRoute } from '../../config/route-config';
import { Button } from '@rentacenter/racstrap';
import { SaveConfigurationsFormContainer } from './SaveConfigurationsFormContainer';
import {
  useConfigurationsDispatchContext,
  useConfigurationsStateContext
} from '../../context/configurations/configurations-contexts';
import { setInitialStateAction } from '../../context/configurations/selected-stores/selected-stores-actions';
import { getErrorAction, getResetAction } from '../../context/api-actions';
import {
  ToastType,
  useToastActionsContext
} from '../common/toast/ToastProvider';
import { Modal, ModalButtons, ModalContent } from '../common/modal/Modal';
import { configSubmitBtnId } from '../edit-configs/form/EditConfigurationsForm';

export const saveConfigsTestId = 'saveConfigsTestId';

export const SaveConfigs: FC = () => {
  const history = useHistory();
  const { showToast } = useToastActionsContext();
  const [noChangesShown, setNoChangesShown] = useState(false);

  const {
    submitConfigurations: { success, isPending, error },
    selectedStores: { selectedCriteria }
  } = useConfigurationsStateContext();
  const dispatch = useConfigurationsDispatchContext();
  const selectedStoresSelectors = useContext(SelectedStoresSelectorsContext);

  const selectedStoresExist = selectedStoresSelectors.arePresent();

  useEffect(() => {
    if (success || error) {
      if (error === 'no change') {
        setNoChangesShown(true);
        return;
      }

      dispatch(setInitialStateAction(selectedCriteria));
      dispatch(getResetAction());
      history.push(ConfigurationsRoute.Index);

      success
        ? showToast(
            ToastType.Success,
            'Your configuration changes have been successfully applied and are now active.'
          )
        : showToast(
            ToastType.Error,
            'There was an error while applying the selected configurations to your stores. Please contact support for help.'
          );
    }
  }, [success, error, dispatch, selectedCriteria, history, showToast]);

  const submitForm = () => {
    document.querySelector<HTMLButtonElement>(`#${configSubmitBtnId}`)?.click();
  };

  return useMemo(
    () => (
      <>
        <Modal
          isOpen={noChangesShown}
          onClose={() => {
            dispatch(getErrorAction(''));
            setNoChangesShown(false);
          }}
          title="No changes detected"
        >
          <ModalContent>
            You must change at least one field in order to save
          </ModalContent>
          <ModalButtons>
            <Button
              color="primary"
              onClick={() => {
                dispatch(getErrorAction(''));
                setNoChangesShown(false);
              }}
            >
              Ok
            </Button>
          </ModalButtons>
        </Modal>
        {selectedStoresExist ? (
          <div data-testid={saveConfigsTestId}>
            <Box pr="1rem">
              <ScrollableContent subtractFromHeight={{ xs: '127', lg: '297' }}>
                <SaveConfigurationsFormContainer />
              </ScrollableContent>
            </Box>
            <Footer>
              <ConfigBackButton path={ConfigurationsRoute.Edit} />
              <Button
                color="primary"
                type="submit"
                disabled={isPending}
                onClick={submitForm}
              >
                {isPending ? 'Saving' : 'Save'}
              </Button>
            </Footer>
          </div>
        ) : (
          <Redirect to={ConfigurationsRoute.Select} />
        )}
      </>
    ),
    [noChangesShown, selectedStoresExist, isPending, dispatch]
  );
};
