import React from 'react';

import styles from './Fees.module.scss';

import { ConfigurationGroupTitle } from '../../../../../config/edit-configs-form-settings';
import { FeeAmount } from './FeeAmount';
import { Configurations } from '../../../../../domain/Configuration/Configurations';
import { allFeeKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsSelectorsContext } from '../../../../../context/configurations/configurations-contexts';
import { CollapsableCard } from '@rentacenter/racstrap';
import { ApplyProcessingFee } from './ApplyProcessingFee';
import { StateFees } from './StateFees/StateFees';
import { StatesTabs } from '../../StatesTabs/StatesTabs';

export interface CommonProps {
  readonly isCollapseHidden?: boolean;
  readonly disabled?: boolean;
  readonly configurations: Configurations;
}

export const collapsibleCardTestId = 'collapsibleCardTestId';
export const stateFeesTestId = 'stateFeesContainer';

export const Fees = (props: CommonProps) => {
  const { disabled, configurations } = props;
  const { countUniqRules } = useConfigurationsSelectorsContext();

  const commonProps: CommonProps = {
    configurations,
    disabled
  };

  return (
    <CollapsableCard
      className={styles.card}
      data-testid={collapsibleCardTestId}
      title={`${ConfigurationGroupTitle.fees} (${countUniqRules(allFeeKeys)})`}
      expanded={disabled}
    >
      <div className={styles.processingFees}>
        <div className={styles.applyProcessingFees}>
          <ApplyProcessingFee {...commonProps} />
        </div>
        <div>
          <FeeAmount {...commonProps} />
        </div>
      </div>

      <StatesTabs
        className={styles.stateSelectorContainer}
        data-testid={stateFeesTestId}
        rulesKeys={Object.values(allFeeKeys)}
        scrollOnErrors={!disabled}
        renderState={abbr => (
          <StateFees
            currentStateAbbr={abbr}
            configurations={configurations}
            disabled={disabled}
          />
        )}
      />
    </CollapsableCard>
  );
};
