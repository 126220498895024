import { Dispatch } from 'react';
import { CancelToken } from 'axios';

import { getConfigurations, submitConfigurations } from '../../api/api';
import { Configurations } from '../../domain/Configuration/Configurations';
import {
  ApiAction,
  getErrorAction,
  getLoadingAction,
  getSuccessAction
} from '../api-actions';
import { SelectedStoresState } from './selected-stores/selected-stores-reducer';
import {
  getInitialConfigurationsAction,
  getInitialConfigurationsSuccessAction
} from './initial-configurations/initial-configurations-actions';
import {
  firstStore,
  firstStoresNums,
  selectedStoresByState
} from './selected-stores/selected-stores-selectors';
import { ConfigurationsAction } from './configurations-reducer';

export interface ConfigurationsThunks {
  getInitialConfigurations(
    selectedStoresState: SelectedStoresState,
    cancelToken: CancelToken
  ): void;
  submitConfigurations(
    configurations: Configurations,
    initialConfigurations?: Configurations
  ): void;
}

export const getInitialConfigurationsThunk = (
  dispatch: Dispatch<ConfigurationsAction>
) => async (
  selectedStoresState: SelectedStoresState,
  cancelToken: CancelToken
) => {
  dispatch(getInitialConfigurationsAction());

  const firstSelectedStore = firstStore(selectedStoresState)();
  const selectedStoreNums = firstStoresNums(selectedStoresState)();

  if (firstSelectedStore) {
    const configurations: Configurations = await getConfigurations(
      selectedStoreNums,
      cancelToken
    );

    dispatch(getInitialConfigurationsSuccessAction(configurations));
  }
};

export const submitConfigurationsThunk = (
  dispatch: Dispatch<ApiAction>,
  selectedStoresState: SelectedStoresState
) => async (
  configurations: Configurations,
  initialConfigurations: Configurations
) => {
  try {
    dispatch(getLoadingAction());

    const selectedStores = selectedStoresByState(selectedStoresState);

    const result = await submitConfigurations(
      configurations,
      selectedStores,
      initialConfigurations
    );

    dispatch(getSuccessAction(result));
  } catch (error) {
    dispatch(getErrorAction(error));
  }
};
