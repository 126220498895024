import { intersection, isEqual, uniq } from 'lodash';

import { ConfigurationKeys } from '../../domain/Configuration/ConfigurationKeys';
import {
  Configuration,
  Configurations
} from '../../domain/Configuration/Configurations';
import { StateAbbreviation } from '../../domain/Store/State';

export interface ConfigurationsSelectors {
  haveConfigurationsChanged(): boolean;
  statesAbbrsForRules(rulesKeys: ConfigurationKeys[]): StateAbbreviation[];
  countUniqRules(rulesKeys: ConfigurationKeys[]): number;
  statesAbbrsSelectedList(): StateAbbreviation[];
}

export const haveConfigurationsChanged = (
  initial?: Configurations,
  changed?: Configurations
) => (): boolean => !isEqual(initial, changed);

export const statesAbbrsForRules = (configurations?: Configurations) => (
  rules: ConfigurationKeys[]
): StateAbbreviation[] => {
  if (!configurations) {
    return [];
  }

  const abbreviations: StateAbbreviation[] = [];

  Object.entries<Configuration>(configurations).forEach(item => {
    const abbr = item[0] as StateAbbreviation | 'COUNTRY';
    const stateConfig = item[1];

    if (abbr === 'COUNTRY') {
      return;
    } else if (intersection(rules, Object.keys(stateConfig)).length) {
      abbreviations.push(abbr);
    }
  });

  return abbreviations;
};

export const statesAbbrsSelectedList = (
  configurations?: Configurations
) => (): StateAbbreviation[] => {
  if (!configurations) {
    return [];
  }

  return Object.keys(configurations).filter(
    value => value !== 'COUNTRY'
  ) as StateAbbreviation[];
};

export const countUniqRules = (configurations?: Configurations) => (
  rulesKeys: ConfigurationKeys[]
): number => {
  if (!configurations) return 0;

  const allRulesKeys = uniq(
    Object.values<Configuration>(configurations)
      .map(configuration => (configuration ? Object.keys(configuration) : []))
      .flat()
  );
  return intersection(Object.values(rulesKeys), allRulesKeys).length;
};

export const getUniqRules = (configurations?: Configurations) => (
  rulesKeys: ConfigurationKeys[]
): string[] => {
  if (!configurations) return [];

  const allRulesKeys = uniq(
    Object.values<Configuration>(configurations)
      .map(configuration => (configuration ? Object.keys(configuration) : []))
      .flat()
  );
  return intersection(Object.values(rulesKeys), allRulesKeys);
};

export const firstState = (
  configurations?: Configurations
): StateAbbreviation => {
  if (!configurations) return '' as StateAbbreviation;
  return Object.keys(configurations)
    .filter(
      key => key !== 'COUNTRY' && Object.keys(StateAbbreviation).includes(key)
    )
    .sort()[0] as StateAbbreviation;
};
