import React, { useContext } from 'react';
import { get } from 'lodash';

import { Switch } from '../../../../common/form/switch/Switch';
import { PaymentKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { Configurations } from '../../../../../domain/Configuration/Configurations';
import { FieldChangedContext } from '../../FieldChangedContext';
import { useConfigurationsStateContext } from '../../../../../context/configurations/configurations-contexts';

export interface Props {
  readonly disabled?: boolean;
  readonly configurations: Configurations;
}

export const SameAsCashDays = (props: Props) => {
  const { disabled, configurations } = props;
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();
  const { fieldChanged } = useContext(FieldChangedContext);

  const fieldName = `COUNTRY.${PaymentKeys.SameAsCashDays}`;
  const fieldValue = `COUNTRY.${PaymentKeys.SameAsCashDays}.value`;
  const presentInApi = !!get(initialConfigurations, fieldName);

  return (
    <Switch
      label="Allow Same As Cash Purchases"
      name={fieldValue}
      defaultChecked={get(configurations, fieldValue)}
      disabled={disabled || !presentInApi}
      changed={fieldChanged(fieldValue)}
      isValueVisible
    />
  );
};
