import { GenericObject } from '../../utils/GenericObject';
import { Store } from '../../domain/Store/Store';
import { ApiAction, ApiActionType } from '../api-actions';

export interface StoresState {
  stores?: Store[];
  isPending: boolean;
  error?: GenericObject;
}

export const initialState: StoresState = {
  isPending: false
};

export const storesReducer = (
  state: StoresState,
  action: ApiAction
): StoresState => {
  switch (action.type) {
    case ApiActionType.LOADING:
      return {
        stores: undefined,
        isPending: true,
        error: undefined
      };

    case ApiActionType.SUCCESS:
      return {
        stores: action.payload,
        isPending: false,
        error: undefined
      };

    case ApiActionType.ERROR:
      return {
        stores: undefined,
        isPending: false,
        error: action.payload
      };

    default:
      return state;
  }
};
