import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import styles from '../Payment.module.scss';

import { Select } from '../../../../../common/form/select/Select';
import { StateAbbreviation } from '../../../../../../domain/Store/State';
import { Configurations } from '../../../../../../domain/Configuration/Configurations';
import { PaymentKeys } from '../../../../../../domain/Configuration/ConfigurationKeys';
import { useConfigurationsStateContext } from '../../../../../../context/configurations/configurations-contexts';
import { EPOPercentOfCashPriceAndRentPaid } from './EPOPercentOfCashPriceAndRentPaid';
import { EPOPercentOfRemainingRent } from './EPOPercentOfRemainingRent';
import { AcquisitionCost } from './AcquisitionCost';

interface Props {
  readonly currentStateAbbr: StateAbbreviation;
  readonly disabled?: boolean;
  readonly configurations: Configurations;
}

const strategies = [
  {
    label: 'Cash Price and Rent Paid',
    value: 'cashPriceAndRentPaid',
    mapping: PaymentKeys.EPOPercentCashPriceRentPaid
  },
  {
    label: 'Remaining Rent Strategy',
    value: 'remainingRentStrategy',
    mapping: PaymentKeys.EPOPercentRemainingRent
  },
  {
    label: 'Rent And Cash Price Total Cost Ratio',
    value: 'rentAndCashPriceTotalCostRatioStrategy',
    mapping: PaymentKeys.EPOCachePriceTotalCostRatio
  },
  {
    label: 'Cash Price And Terms',
    value: 'cashPriceAndTermsStrategy',
    mapping: PaymentKeys.EPOCachePriceAndTerms
  },
  {
    label: 'Rent Paid And Time Price Differential',
    value: 'rentPaidAndTimePriceDifferentialStrategy',
    mapping: PaymentKeys.EPORentPaidAcquisitionCost
  }
];

export const EPO = ({ currentStateAbbr, disabled, configurations }: Props) => {
  const { control } = useFormContext();
  const {
    initialConfigurations: { configurations: initialConfigurations }
  } = useConfigurationsStateContext();

  const fieldName = `${currentStateAbbr}.epoStrategy`;

  const initialValue = strategies.find(strategy =>
    get(initialConfigurations, `${currentStateAbbr}.${strategy.mapping}`)
  )?.value;
  const defaultValue = get(configurations, fieldName) || initialValue;
  const selectedEPO = useWatch({ name: fieldName, defaultValue });

  return (
    <div className={styles.epo}>
      <div className={styles.epoStrategy}>
        <Controller
          as={Select}
          control={control}
          name={fieldName}
          label="EPO"
          options={strategies}
          placeholder="Select Strategy"
          disabled={true}
          changed={selectedEPO !== initialValue && disabled}
          defaultValue={defaultValue}
          tooltip={
            <>
              EPO Types:
              <br />
              Remaining Rent – Percentage of remaining rent is used towards the
              EPO for the agreement
            </>
          }
        />
      </div>
      <div className={styles.epoPercent}>
        {
          ({
            cashPriceAndRentPaid: (
              <EPOPercentOfCashPriceAndRentPaid
                currentStateAbbr={currentStateAbbr}
                disabled={disabled}
              />
            ),
            remainingRentStrategy: (
              <EPOPercentOfRemainingRent
                currentStateAbbr={currentStateAbbr}
                disabled={disabled}
              />
            ),
            rentPaidAndTimePriceDifferentialStrategy: (
              <AcquisitionCost currentStateAbbr={currentStateAbbr} />
            )
          } as Record<string, any>)[selectedEPO]
        }
      </div>
    </div>
  );
};
