import React from 'react';
import { matchPath, withRouter, RouteComponentProps } from 'react-router-dom';
import { Stepper } from '@rentacenter/racstrap';

import classes from './Steps.module.scss';

import { ConfigurationsRoute } from '../../../config/route-config';

export const stepsTestId = 'stepsTestId';

export const Steps = withRouter((props: RouteComponentProps) => {
  const {
    location: { pathname }
  } = props;

  const isEditRoute = matchPath(pathname, {
    path: ConfigurationsRoute.Edit
  });

  const isSaveRoute = matchPath(pathname, {
    path: ConfigurationsRoute.Save
  });

  const steps = [
    { title: 'Select stores' },
    { title: 'Edit configurations' },
    { title: 'Preview and save' }
  ];

  let activeStep = 0;

  if (isEditRoute) {
    activeStep = 1;
  } else if (isSaveRoute) {
    activeStep = 2;
  }

  return (
    <Stepper
      data-testid={stepsTestId}
      className={classes.stepper}
      steps={steps}
      activeStep={activeStep}
    />
  );
});
