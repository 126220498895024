import React from 'react';
import { get } from 'lodash';

import { Switch } from '../../../../common/form/switch/Switch';
import { PaymentKeys } from '../../../../../domain/Configuration/ConfigurationKeys';
import { Configurations } from '../../../../../domain/Configuration/Configurations';

export interface Props {
  readonly configurations: Configurations;
}

export const AllowFinalPaymentAutopay = (props: Props) => {
  const { configurations } = props;

  const fieldValue = `COUNTRY.${PaymentKeys.AllowFinalPaymentAutopay}.value`;

  return (
    <Switch
      label="Allow final payment autopay"
      name={fieldValue}
      defaultChecked={get(configurations, fieldValue)}
      disabled
      isValueVisible
    />
  );
};
