import React from 'react';
import { Controller } from 'react-hook-form';

import { FeeKeys } from '../../../../../../../domain/Configuration/ConfigurationKeys';
import { TooltipItem } from '../../../../../../common/TooltipItem';
import { AutoNumeric } from '../../../../../../common/form/auto-numeric/AutoNumeric';
import { ErrorIcon } from '../../../../../../common/form/error-icon/ErrorIcon';
import { useRuleField } from '../../../../useRuleField';
import { StateFeesProps } from '../StateFees';

export const DaysBeforeLateFeeAppliedMonthly = (props: StateFeesProps) => {
  const { disabled, currentStateAbbr } = props;

  const {
    presentInApi,
    fieldError,
    changed,
    fieldName,
    options,
    rules
  } = useRuleField(`${currentStateAbbr}.${FeeKeys.GracePeriodMonthly}`);

  return (
    <TooltipItem
      title={fieldError?.message}
      arrow
      placement="bottom-start"
      TransitionProps={{ exit: false }}
    >
      <Controller
        as={AutoNumeric}
        name={fieldName}
        options={options}
        rules={presentInApi && rules}
        size="small"
        disabled={disabled || !presentInApi}
        invalid={!!fieldError}
        icon={!!fieldError && <ErrorIcon />}
        changed={changed}
      />
    </TooltipItem>
  );
};
