import React, { ChangeEvent } from 'react';
import { Checkbox, Card } from '@rentacenter/racstrap';

import classes from './StoreListItem.module.scss';

export interface StoreListItemSelectEvent {
  checked: boolean;
  criteriaName: string;
}

interface Props {
  criteriaName: string;
  numberOfStores: number;
  checked: boolean;
  onCheck?: (event: StoreListItemSelectEvent) => void;
}

export const storeListItemTestId = 'storeListItemTestId';
export const checkBoxTestId = 'checkBoxTestId';

export const StoreListItem = (props: Props) => {
  const { checked, criteriaName, numberOfStores, onCheck } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onCheck) {
      const {
        target: { checked, name: criteriaName }
      } = event;

      onCheck({
        checked,
        criteriaName
      });
    }
  };

  return (
    <Card
      selected={checked}
      data-testid={storeListItemTestId}
      className={classes.listItemCheck}
    >
      <Checkbox
        data-testid={checkBoxTestId}
        color="primary"
        size="small"
        name={criteriaName}
        checked={checked}
        onChange={handleChange}
        labelText={criteriaName}
      />
      <p className={classes.listItemCheckInfo}>
        {numberOfStores} {numberOfStores === 1 ? 'store' : 'stores'}
      </p>
    </Card>
  );
};
