import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { merge } from 'lodash';

import { EditConfigurationsForm } from './EditConfigurationsForm';
import {
  useConfigurationsDispatchContext,
  useConfigurationsStateContext,
  useUserConfigurationsThunksContext
} from '../../../context/configurations/configurations-contexts';
import { changeConfigurationsAction } from '../../../context/configurations/changed-configurations/changed-configurations-actions';
import { Configurations } from '../../../domain/Configuration/Configurations';
import { getCancelTokenSource } from '../../../api/client';

interface Props {
  isCollapseHidden?: boolean;
}

export const EditConfigurationsFormContainer = (props: Props) => {
  const { isCollapseHidden } = props;

  const {
    selectedStores,
    initialConfigurations: { configurations },
    changedConfigurations
  } = useConfigurationsStateContext();

  const dispatch = useConfigurationsDispatchContext();

  const { getInitialConfigurations } = useUserConfigurationsThunksContext();

  const [defaultConfigurations, setDefaultConfigurations] = useState<
    Configurations | undefined
  >(undefined);

  useEffect(() => {
    if (defaultConfigurations) {
      return;
    }

    if (changedConfigurations) {
      setDefaultConfigurations(changedConfigurations);
      return;
    }

    if (configurations) {
      const defaultConfig = merge({}, configurations);
      setDefaultConfigurations(defaultConfig);
      return;
    }

    const cancelToken = getCancelTokenSource();

    getInitialConfigurations(selectedStores, cancelToken.token);

    return cancelToken.cancel;
  }, [
    defaultConfigurations,
    configurations,
    changedConfigurations,
    selectedStores,
    getInitialConfigurations
  ]);

  const handleChange = useCallback(
    (configurations: Configurations, isTouched: boolean) => {
      dispatch(changeConfigurationsAction(configurations, isTouched));
    },
    [dispatch]
  );

  return useMemo(
    () => (
      <>
        {defaultConfigurations && (
          <EditConfigurationsForm
            isCollapseHidden={isCollapseHidden}
            disabled={false}
            defaultConfigurations={defaultConfigurations}
            onChange={handleChange}
          />
        )}
      </>
    ),
    [defaultConfigurations, isCollapseHidden, handleChange]
  );
};
