import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputField } from '../../../../common/form/input-field/InputField';
import { PaymentKeys } from '../../../../../domain/Configuration/ConfigurationKeys';

export const ReferralRentalPaymentCount = () => {
  const { control } = useFormContext();
  const fieldName = `COUNTRY.${PaymentKeys.ReferralRentalPaymentCount}.value`;
  return (
    <Controller
      as={InputField}
      control={control}
      name={fieldName}
      label="Referral Rental Payment Count"
      disabled
      tooltip="Number of payments needed before
      adding the referral count is increased for
      a customer."
    />
  );
};
