import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rentacenter/racstrap';

interface BackButtonProps {
  path: string;
}

type Props = RouteComponentProps & BackButtonProps;

export const configBackButtonTestId = 'configBackButtonTestId';

export const ConfigBackButton = withRouter(({ history, path }: Props) => {
  const handleClick = () => {
    history.push(path);
  };

  return (
    <Button
      color="primary"
      onClick={handleClick}
      data-testid={configBackButtonTestId}
    >
      Back
    </Button>
  );
});
