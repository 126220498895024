import React, {
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { List } from '@material-ui/core';

import styles from './StatesTabs.module.scss';

import { StateAbbreviation } from '../../../../domain/Store/State';
import { ConfigurationKeys } from '../../../../domain/Configuration/ConfigurationKeys';
import { ListItem } from '../../../common/list-item/ListItem';
import { TabPanel } from '../../../common/tab/TabPanel';
import { useConfigurationsSelectorsContext } from '../../../../context/configurations/configurations-contexts';
import { errorsForRules } from '../../../../utils/errors-for-rules';
import { getNameForAbbreviation } from '../../../../config/usStates';

const a11yProps = (index: number) => ({
  id: `edit-tab-${index}`,
  'aria-controls': `edit-tabpanel-${index}`
});

interface Props extends HTMLAttributes<HTMLDivElement> {
  readonly className?: string;
  readonly rulesKeys: ConfigurationKeys[];
  readonly scrollOnErrors: boolean;
  readonly 'data-testid'?: string;
  readonly renderState: (stateAbbreviation: StateAbbreviation) => ReactNode;
}

export const StatesTabs = (props: Props) => {
  const {
    className,
    renderState,
    rulesKeys,
    scrollOnErrors,
    'data-testid': testId
  } = props;

  const { errors } = useFormContext();
  const generalErrors = errorsForRules(errors, rulesKeys);

  const { statesAbbrsSelectedList } = useConfigurationsSelectorsContext();
  const statesAbbreviations = statesAbbrsSelectedList().sort(
    (a: string, b: string) => {
      const result = a.localeCompare(b);
      if (generalErrors[a] && generalErrors[b]) {
        return result;
      } else if (generalErrors[a]) {
        return -1;
      } else if (generalErrors[b]) {
        return 1;
      } else {
        return result;
      }
    }
  );

  const [selectedValue, setSelectedValue] = useState(statesAbbreviations[0]);

  const listItemRef = useRef();
  listItemRef.current = undefined;

  useEffect(() => {
    if (!isEmpty(generalErrors) && scrollOnErrors) {
      Object(listItemRef)?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [generalErrors, scrollOnErrors]);

  return (
    <div
      data-testid={testId || 'stateSelector'}
      className={clsx(styles.mainContainer, className)}
    >
      <List
        className={styles.stateSelector}
        component="nav"
        aria-label="state list"
      >
        {statesAbbreviations.map(
          (stateAbbreviation: StateAbbreviation, index: number) => {
            return (
              <ListItem
                key={stateAbbreviation}
                isError={generalErrors[stateAbbreviation]}
                innerRef={generalErrors[stateAbbreviation] && listItemRef}
                selected={selectedValue === stateAbbreviation}
                handleListItemClick={() => {
                  setSelectedValue(stateAbbreviation);
                }}
                {...a11yProps(index)}
              >
                {getNameForAbbreviation(stateAbbreviation)}
              </ListItem>
            );
          }
        )}
      </List>
      <div className={styles.content}>
        {statesAbbreviations.map(
          (stateAbbreviation: StateAbbreviation, index: number) => {
            return (
              <TabPanel
                key={stateAbbreviation}
                value={statesAbbreviations.indexOf(selectedValue)}
                index={index}
              >
                {renderState(stateAbbreviation)}
              </TabPanel>
            );
          }
        )}
      </div>
    </div>
  );
};
