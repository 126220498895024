import React from 'react';

import { Store } from '../../../../domain/Store/Store';
import { SelectCriteria } from '../../../../context/configurations/selected-stores/selected-stores-reducer';

import styles from './SelectionPreviewItem.module.scss';

interface Props {
  store: Store;
  selectedCriteria?: string;
}

export const selectionPreviewItemTestId = 'selectionPreviewItemTestId';

export const SelectionPreviewItem = (props: Props) => {
  const { store, selectedCriteria } = props;
  const {
    storeNum,
    storeName,
    state: { abbreviation }
  } = store;

  return (
    <li data-testid={selectionPreviewItemTestId}>
      <p className={styles.selectionPreviewItem}>
        <span className={styles.selectionPreviewItemLeft}>{storeNum}</span>{' '}
        <span>
          {storeName}
          {selectedCriteria === SelectCriteria.ByEntity && (
            <span>
              {', '}
              <strong>{abbreviation}</strong>
            </span>
          )}
        </span>
      </p>
    </li>
  );
};
